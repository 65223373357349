import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { callApi } from "../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Modal from "react-modal";
import checkIcon from "./check.png";
import cancelIcon from "./cross-dark.png";
import loadingIcon from "./loading2.gif";
import loadingIcon1 from "./loading3.gif";
import { InfinitySpin } from "react-loader-spinner";
import ButtonPrimary from "./Buttons/ButtonPrimary";
import MasterCard from "../images/masterCard.png";
import Mestro from "../images/mestro.png";
import PayPal from "../images/payPal.png";
import Visa from "../images/visa.png";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "gray",
      color: "gray",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#6b7280" },
      "::placeholder": { color: "#6b7280" },
    },
    invalid: {
      iconColor: "#fe3333",
      color: "#fe3333",
    },
  },
};

const REACT_APP_BASE_URL_LIVE = process.env.REACT_APP_BASE_URL_LIVE;

const handleChange = (event) => {

};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 30,
    padding: 43,
  },
};

export default function PaymentForm(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let getToken = localStorage.getItem("token");
  let getIsGuest = localStorage.getItem("isGuest");
  const date = new Date();
  let currentDate = moment(date).format("YYYY-MM-DD");
  let data = props.checkOutdata;
  const cartItems = data.cartItems
  let newsLetterData = props.newsLetterData !== false ? 1 : 0;
  const [success, setSuccess] = useState(false);
  const [pageloader, setPageLoader] = useState(false);
  const [isCardDetailsValid, setIsCardDetailsValid] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [openDangerModal, setOpenDangerModal] = useState(false);
  const [isPaymentStatus, setIsPaymentStatus] = useState(false);
  const [isPaymentRes, setIsPaymentRes] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  let userDetails = {};

  if (localStorage.getItem("user") != "undefined") {
    userDetails = JSON.parse(localStorage.getItem("user"));
  }

  useEffect(() => {
    elements &&
      elements.getElement(CardElement).on("change", (event) => {
        if (event.complete) {
          setIsCardDetailsValid(true);
        } else {
          setIsCardDetailsValid(false);
        }
      });
  }, [elements]);


  

  // handle booking
  const handleBooking = async (payment) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const payload = new FormData();
    payload.append("pickup_id", props?.productDetails?.booking?.pickup_id);
    payload.append("seats_qty", props?.productDetails?.updatedQty);
    payload.append("event_id",  Number(props?.productDetails?.booking?.event_id));
    payload.append("price",parseFloat(props?.checkOutdata?.cartItems[0]?.price));
    payload.append("total", props?.checkOutdata?.cartItems[0]?.totaLPrice);
    payload.append("discount", Number(props?.checkOutdata?.discount));
    payload.append("grand_total",Number(props?.checkOutdata?.cartItems[0]?.grandTotal));
    payload.append("date_of_booking", props?.productDetails?.booking?.date);
    payload.append("billing_fname", data?.firstname);
    payload.append("billing_lname", data?.lastname);
    payload.append("user_id", userInfo.id);
    payload.append("billing_phone", data?.mobile ? data?.mobile : "0909090909");
    Object.keys(props?.productDetails?.passangeDetails).map((item) => {
      return payload.append(item, props?.productDetails?.passangeDetails[item]);
    });
    payload.append("transaction_id", payment.id);
    payload.append("coupon_id", data?.coupon_id);
    payload.append("transaction_data", JSON.stringify(payment));
    payload.append("payment_method", "Stripe");
    callApi("/make-booking", payload, "post")
      .then((res) => {
        setOpenLoadingModal(true);
        const BookedItems = res?.data?.data;

        navigate("/Paydone", { state: { BookedItems } });
      })
      .catch((err) => {
        toast.error("something went wrong", {
          position: "top-right",
        });
      });
  };

  const checkPaymentStatus = () => {
    callApi(
      `user_payment_info/${data.user_id}`,
      { user_id: data.user_id },
      "get"
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setOpenLoadingModal(true);
          const resData = res?.data?.data.transaction_id;
          setIsPaymentRes(res?.data?.data);
          setTimeout(() => {
            storePassengersData(resData);
          }, 1000);
        } else {
          setIsPaymentStatus(false);
          setOpenLoadingModal(false);
          setTimeout(() => {
            setPageLoader(false);
            setOpenDangerModal(true);
          }, 1000);
        }
      })
      .catch((err) => {
        setOpenLoadingModal(false);
        setOpenDangerModal(true);
      });
  };

  const storePassengersData = (val) => {
    callApi(
      `store_passenger`,
      {
        transaction_id: val,
        user_id: data.user_id,
        booking_details: cartItems,
      },
      "post"
    )
      .then((res) => {
        if (res?.data) {
          setOpenLoadingModal(false);
          setIsPaymentStatus(true);
          setTimeout(() => {
            setIsPaymentStatus(false);
            const BookedItems = res?.data?.data;
            const cartItemsEmpty = [];
            if (getIsGuest === "true") {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.setItem("isGuest", false);
            }
            localStorage.setItem("cartItems", JSON.stringify(cartItemsEmpty));
            navigate("/Paydone", { state: { BookedItems } });
            setPageLoader(false);
          }, 2000);
        } else {
          setOpenLoadingModal(false);
          setIsPaymentStatus(false);
          setPageLoader(false);
          setOpenDangerModal(true);
        }
      })
      .catch((err) => {
        setOpenLoadingModal(false);
        setOpenDangerModal(true);
        
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setPageLoader(false);
      return;
    }
    if (isCardDetailsValid) {
    
      setOpenLoadingModal(true);
      const { client_secret } = await fetch(
        `${REACT_APP_BASE_URL_LIVE}intent-payments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            firstname: props.checkOutdata.firstname,
            lastname: props.checkOutdata.lastname,
            email: props.checkOutdata.email,
            phone: props.checkOutdata.phone,
            description: data.description,
            coupon_id: data.coupon_id,
            user_id: data.user_id,
            newsletter_status: newsLetterData,
            event_id: JSON.stringify(cartItems[0]?.event_id),
            amount: Number(props?.checkOutdata?.cartItems[0]?.grandTotal),
            booking_date: currentDate,
          }),
        }
      )
        .then((r) => r.json())
        .catch((err) => {
       
          setOpenLoadingModal(false);
          setPageLoader(false);
        });
      const { paymentIntent } = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: data.firstname + " " + data.lastname,
            email: data.email,
            phone: data.phone,
            address: { postal_code: "90210" },
          },
        },
      });
      stripe.retrievePaymentIntent(client_secret).then(function (response) {
        if (
          response.paymentIntent ||
          response.paymentIntent.status === "succeeded"
        ) {
         
          handleBooking(response.paymentIntent);
        } else {
          setPageLoader(false);
        }
      });
    } else {
      setPageLoader(false);
      toast.error("Card details is required", {
        position: "top-right",
      });
    }
  };




  const localStorageMode = localStorage.getItem("theme");
  const iconSrc = localStorageMode === "light" ? loadingIcon1 : loadingIcon;

  return (
    <>
      {!success ? (
        <div className="CardElementForm ">
          {pageloader ? <div> loading </div> : null}
          <div className="w-full bg-[#F7F8FA] dark:bg-[#3B3B3B] rounded-lg">
            <div className="p-2 pb-4 md:p-3 ">
              <div className="flex my-4 items-center dark:text-[#D8DBDE] gap-3 justify-start">
                {/* <input type="radio" /> */}
                <div>
                  <p className="text-sm">Pay With Stripe </p>
                  <span className="text-xs">
                    We accept all major credit cards
                  </span>
                </div>
              </div>

              <div className="">
                <div className="flex flex-wrap justify-between mx-6 my-5">
                  <img
                    className="mt-3 w-12 object-contain"
                    src={MasterCard}
                    alt="mastercard"
                  />
                  <img
                    className="mt-3 w-12 object-contain"
                    src={Visa}
                    alt="visa"
                  />
                  <img
                    className="mt-3 w-12 object-contain"
                    src={PayPal}
                    alt="paypal"
                  />
                  <img
                    className="mt-3 w-12 object-contain"
                    src={Mestro}
                    alt="maestro"
                  />
                </div>

                <div className="md:mt-6 CardElementFormInner dark:bg-[#3B3B3B] dark:text-[#D8DBDE] bg-white text-gray-400 px-3 py-4 border border-gray-300 rounded-lg">
                  <fieldset className="FormGroup">
                    <div className="FormRow">
                      <CardElement
                        options={CARD_OPTIONS}
                        onReady={(e) => e.focus()}
                        onChange={handleChange}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>

              <p className="my-4 text-xs  dark:text-[#D8DBDE] text-gray-500">
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our privacy policy.
              </p>

              <div className="text-start my-6">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="text-white  bg-black text-sm py-2 px-10 rounded-md button"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2>Payment Success!</h2>
        </div>
      )}
      {/* Same as */}
      <ToastContainer />
      {/* success modal */}
      <Modal
        isOpen={isPaymentStatus}
        style={customStyles}
        contentLabel="Success"
      >
        <div className="flex bg-white dark:bg-[#3B3B3B] items-center justify-center flex-col mt-2">
          <img src={checkIcon} width="100" className="checkIcon" />
          <span className="text-2xl font-bold text-skyBlue-300 text-center">
            {`Your payment was successful`}
          </span>
          <InfinitySpin color="#00c4ff" />
          <div className="mt-4 text-center">
            <p className="text-[#111827] dark:text-[#D8DBDE]">
              Thank you for your payment.
            </p>
            <p className="dark:text-[#D8DBDE]  text-[#3f4858]">
              We will be in contact with more details shortly.
            </p>
          </div>
        </div>
      </Modal>
      {/* Loading modal */}
      <Modal
        isOpen={openLoadingModal}
        style={customStyles}
        contentLabel="Loading"
      >
        <div className="flex m-2 justify-center items-center flex-col mt-10">
          <InfinitySpin
            visible={true}
            width="200"
            color="#00c4ff"
            ariaLabel="infinity-spin-loading"
          />
          <span className="dark:text-[#D8DBDE] text-2xl font-semibold text-center text-[#111827] ">
            {`Payment Inprogress`}
          </span>
          <div className="mt-4 text-center text-">
            <p className="dark:text-[#D8DBDE]  text-[#3f4858]">
              Your payment is in inprogress please wait , do not refresh page.
            </p>
            <p className="dark:text-[#D8DBDE]  text-[#3f4858]">
              {" "}
              We will be in contact with more details shortly.
            </p>
          </div>
        </div>
      </Modal>
      {/* Failed modal */}
      <Modal
        isOpen={openDangerModal}
        style={customStyles}
        contentLabel="failed"
      >
        <div>
          <div className="flex justify-center items-center flex-col mt-2">
            <img src={cancelIcon} width="100" className="cancelIcon" />
            <span className="text-2xl font-bold text-center text-red-500 ">
              {`Your payment was Failed`}
            </span>
            <div className="mt-4 text-center">
              <p className="text-white">
                Thank you for showing interest in Event Bus.
              </p>
              <p className="text-white">
                Your payment was a failed , We will be in contact with more
                details shortly.
              </p>
            </div>

            <div className="flex justify-center items-center flex-row mt-12">
              <button
                onClick={() => {
                  setOpenDangerModal(false);
                }}
                className="w-100 bg-skyBlue-300 hover:bg-skyBlue-800"
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
