import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";

const OrderDetail = () => {
  const location = useLocation();
  const orderDetails = location.state.data;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(orderDetails);
  }, [orderDetails]);


  return (
    <>
      <React.Fragment>
        {/* orderDetails details Section */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2 className="text-3xl font-semibold">Details</h2>

            <Link to="/Myaccount">
              <ButtonPrimary
                className="btn btn-primary py-1"
                // onClick={() => setShowDetails(false)}
                //   onClick={goBackHandle}
              >
                Go Back
              </ButtonPrimary>
            </Link>
          </div>
          {data ? (
            <>
              <p className="text-1xl" style={{ marginTop: 50 }}>
                orderDetails
                <span className="text-primary ">
                  <strong>{data?.orderDetails_id}</strong>
                </span>
                was place on{" "}
                <span className="text-primary ">
                  <strong>{data?.booking_date}</strong>
                </span>
                and is currently
                <span className="text-primary">
                  <strong>Completed</strong>
                </span>
              </p>
              <div className="table-responsive">
                <table
                  className="productDetailsTable borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 w-100"
                  style={{ marginTop: 10, marginBottom: 0 }}
                >
                  <thead className="position-sticky ">
                    <tr>
                      <th>Product</th>
                      <th style={{ textAlign: "right" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                    <>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <h4 className="text-primary text-2xl font-semibold">
                            {data?.event_name}
                          </h4>
                          <strong>Concert Date :</strong>
                          <p>{data?.date_of_booking}</p>
                          <strong>County you wish to travel from :</strong>
                          <p>{data?.county_name}</p>
                          <strong>Pick Up Points & Departure Times :</strong>
                          <p>
                            {data?.pickup?.pickup_address} At{" "}
                            {data?.pickup?.formatted_pickup_time}
                          </p>
                        </td>
                        <td className="text-right borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 ">
                          <strong>{data?.total}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <strong>Subtotal :</strong>
                        </td>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 text-right" >
                          <strong>{data?.total}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <strong>Subtotal :</strong>
                        </td>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 text-right" >
                          <strong>{data?.total}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <strong>Payment Method :</strong>
                        </td>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 text-right" >Credit or Debit card</td>
                      </tr>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <strong>Total :</strong>
                        </td>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 text-right" >
                          <strong>{data?.total}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                          <strong>Note :</strong>
                        </td>
                        <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 text-right" >{data?.booking_note}</td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
              <div
          
                style={{ marginTop: 40 }}
              >
                <ButtonPrimary className=" btn-primary">
                  Download Invoice
                </ButtonPrimary>
              </div>
            </>
          ) : (
            <Skeleton height={400} count={1} />
          )}
        </div>
        {/* Tickets details Section */}
        <div className="ticket-details-section" style={{ marginTop: 50 }}>
          <div className="d-flex align-items-center justify-space-between">
            <h2 className="text-3xl font-semibold">Tickets Details</h2>
          </div>
          {data ? (
            <>
              <div className="table-responsive">
                <table
                  className="productDetailsTable borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 w-100"
                  style={{ marginTop: 10, marginBottom: 0 }}
                >
                  <thead className="position-sticky">
                    <tr className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <th style={{ width: "300px" }}>Ticket Type</th>
                      <th style={{ width: "300px" }}>Extras</th>
                      <th style={{ textAlign: "center" }}>Ticket</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.attendees?.map((row, index) => {
                      return (
                        <>
                          <tr>
                            <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'>
                              <strong>{row?.event_name}</strong>
                            </td>
                            <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'>
                              <table className="w-100">
                                <tr>
                                  <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'
                                    style={{
                                      borderDetails: "0px solid",
                                      padding: "3px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Passenger Name :
                                  </td>
                                  <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'
                                    style={{
                                      borderDetails: "0px solid",
                                      padding: "0",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row.attendee_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'
                                    style={{
                                      borderDetails: "0px solid",
                                      padding: "3px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Passenger Number :
                                  </td>
                                  <td className='borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700'
                                    style={{
                                      borderDetails: "0px solid",
                                      padding: "0",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row.attendee_number}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td  class="text-center">
                              <ButtonPrimary className="btn btn-primary py-1">
                                View
                              </ButtonPrimary>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Skeleton height={400} count={1} />
          )}
        </div>
        {data ? (
          <>
            <div className="d-flex align-items-center my-2 justify-space-between">
              <h2 className="text-3xl font-semibold">Event Details</h2>
            </div>

            <div>
              <table
                class="productDetailsTable borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 w-100"
                style={{ marginTop: 10, marginBottom: 0 }}
              >
                <thead className="position-sticky">
                  <tr className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                    <th style={{ width: "300px" }}>Event name</th>
                    <th style={{ width: "300px" }}>Event Address</th>
                    <th style={{ width: "300px" }}>Date</th>

                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <strong>{data?.event?.name}</strong>
                    </td>
                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <table className="w-100">
                        <tr>
                          <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700"
                            style={{
                              borderDetails: "0px solid",
                              padding: "0",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data?.event?.event_address}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <strong>{data?.event?.date_concert}</strong>
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex align-items-center my-2 justify-space-between">
              <h2 className="text-3xl font-semibold">Pickup Point</h2>
            </div>

            <div>
              <table
                className="productDetailsTable borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700 w-100"
                style={{ marginTop: 10, marginBottom: 0 }}
              >
                <thead className="position-sticky">
                  <tr>
                    <th className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700" style={{ width: "300px" }}>Pickup Address</th>
                    <th className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700" style={{ width: "300px" }}>Time</th>
                    <th className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700" style={{ width: "300px" }}>Price</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <strong>{data?.pickup?.pickup_address}</strong>
                    </td>
                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <table className="w-100">
                        <tr>
                          <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700"
                            style={{
                              borderDetails: "0px solid",
                              padding: "0",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data?.pickup?.formatted_pickup_time}
                          </td>
                        </tr>
                      </table>
                    </td>

                    <td className="borderDetails-[1px] borderDetails-darkOrange-300 dark:borderDetails-neutral-700">
                      <strong>{data?.pickup?.price} {"€"}</strong>
                    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <Skeleton height={400} count={1} />
          </>
        )}

        <div style={{ marginTop: 40 }}>
          <Link to="/all-events">
            <ButtonPrimary className="btn btn-primary py-1">
               Again Order
            </ButtonPrimary>
          </Link>
        </div>
        <div style={{ marginTop: 30 }}>
          <h4 className="text-primary text-1xl font-semibold">
            Billing Address
          </h4>
          <p>{data?.billing_fname + " " + data?.billing_lname}</p>
          <p>{data?.billing_phone}</p>

          <p>{data?.billing_email}</p>
        </div>
        <div style={{ marginTop: 20 }}>
          <h4 className="text-primary text-1xl font-semibold">orderDetails Extra</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Email Address verification</p>
            <p>{data?.billing_email}</p>
          </div>
        </div>
      </React.Fragment>
  

      
    </>
  );
};

export default OrderDetail;
