import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { callApi } from "../../Utils/api";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import Layout from "../../Layout";

const SignUp = (props) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [buttonLoading, setIsbuttonLoading] = useState(false);

  const location = useLocation();
  const getData = location?.state;


  const validateEmail = (input) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validatePassword = (input) => {
    // Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, and one number
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    return new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$");
  };

  const handleSignUp = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Basic validation
    if (!firstName) {
      setFirstNameError("First Name is required");
      return;
    }

    if (!lastName) {
      setLastNameError("Last Name is required");
      return;
    }

    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one number"
      );
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    // If validation passes, you can proceed with the sign-up logic
    // For example, make an API call to register the user

    // Simulating a successful sign-up
    // Replace the following line with your actual sign-up logic
    const isSignUpSuccessful = true;

    if (isSignUpSuccessful) {
      // Reset error messages
      setFirstNameError("");
      setLastNameError("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");

      // // Show a success alert
      // setShowSuccessAlert(true);
      setIsbuttonLoading(true);
      SignUpCall();
    }
  };

  const SignUpCall = () => {
    callApi(
      `user/register`,
      {
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      },
      "post"
    )
      .then((response) => {
        let errstatus = response.data;
        // setStatus(errstatus);
        if (errstatus?.errors) {
          setIsbuttonLoading(false);
          let errorMessage = "";
          for (const key in errstatus.errors) {
            errorMessage += `${key}: ${errstatus.errors[key].join(", ")}\n`;
          }
          alert(errorMessage);
       
        } else {
          if (errstatus.token) {
            toast.success("Registration Successfully", {
              position: "top-right",
            });
            navigate('/Myaccount/dashboard')
           localStorage.setItem("token", response?.data?.token);
            localStorage.setItem("isGuest", false);
            localStorage.setItem(
              "user",
              JSON.stringify(response?.data?.userData)
            );
            setIsbuttonLoading(false);

           
          } else {
            toast.warn(response.data.msg, {
              position: "top-right",
            });
            setIsbuttonLoading(false);
          }
        }
      })
      .catch((err) => {
   
        toast.warn(err, {
          position: "top-right",
        });
        setIsbuttonLoading(false);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(""); // Clear first name error when typing
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError(""); // Clear last name error when typing
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear email error when typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(""); // Clear password error when typing
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(""); // Clear confirm password error when typing
  };

  return (
    <React.Fragment>
      <section className="county-section">
        <div className="row p-3 rounded-2xl flex flex-wrap justify-center">
          <div className="w-full h-full sm:w-[50%] sm:h-[50%] dark:bg-[#2B2B2B]  bg-white aspect-w-1 aspect-h-1 rounded-2xl p-5">
            <h1 className="text-3xl  text-skyBlue-300 font-medium leading-[1.15] mb-5 text-center">
              Sign Up
            </h1>
            <form onSubmit={handleSignUp}>
              <div className="mb-4">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium    dark:text-white text-gray-600"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  className={`mt-1 p-2 dark:border-[#676764] dark:bg-transparent   border ${
                    firstNameError ? "border-red-500" : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="Enter your firstname"
                />
                {firstNameError && (
                  <p className="text-red-500 text-sm mt-1">{firstNameError}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium  dark:text-white text-gray-600"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={lastName}
                  onChange={handleLastNameChange}
                  className={`mt-1 p-2   dark:border-[#676764] dark:bg-transparent   border ${
                    lastNameError ? "border-red-500" : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="Enter your lastname"
                />
                {lastNameError && (
                  <p className="text-red-500 text-sm mt-1">{lastNameError}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium  dark:text-white text-gray-600"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={`mt-1 p-2   dark:border-[#676764] dark:bg-transparent   border ${
                    emailError ? "border-red-500" : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="xyz@abc.com"
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium  dark:text-white text-gray-600"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className={`mt-1 p-2   dark:border-[#676764] dark:bg-transparent   border ${
                    passwordError ? "border-red-500" : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="Enter your password"
                />
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmpassword"
                  className="block text-sm font-medium  dark:text-white text-gray-600"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmpassword"
                  name="confirmpassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className={`mt-1 p-2   dark:border-[#676764] dark:bg-transparent   border ${
                    confirmPasswordError
                      ? "border-red-500"
                      : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="Enter your confirm password"
                />
                {confirmPasswordError && (
                  <p className="text-red-500 text-sm mt-1">
                    {confirmPasswordError}
                  </p>
                )}
              </div>

              <ButtonPrimary
                type="submit"
                className={`font-bold py-2 px-4 rounded w-full ${
                  buttonLoading
                    ? "bg-[#F5F5F5] text-black"
                    : "bg-darkOrange-300 hover:bg-[#fcb865] text-white "
                } flex items-center justify-center`}
              >
                {buttonLoading ? (
                  <InfinitySpin color="#00c4ff" width="50"></InfinitySpin>
                ) : (
                  "Sign up"
                )}
              </ButtonPrimary>
            </form>
            <Link to="/login">
              <div className="text-center flex items-center justify-center cursor-pointer mt-1">
                <span className=" dark:text-white hover:text-skyBlue-300 hover:underline text-sm">
                  Already have an account?<span className=" "> Sign in</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <ToastContainer />
      </section>
    </React.Fragment>
  );
};

export default Layout(SignUp);
