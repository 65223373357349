import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { callApi } from "../../Utils/api";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Layout from "../../Layout";
const Login = (props) => {
  const location = useLocation();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setIsButtonLoading] = useState(false);

  const validateEmail = (input) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleLogin = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    // Basic validation
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      return;
    }
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
      return;
    }

    const isLoginSuccessful = true;

    if (isLoginSuccessful) {
      setIsButtonLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    callApi(`user/login`, { email: email, password: password }, "post")
      .then((response) => {
        if (response?.data?.status === 200) {
          toast.success("Congratulations Login Success", {
            position: "top-right",
          });
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("isGuest", false);
          localStorage.setItem("user", JSON.stringify(response?.data?.user));
          setTimeout(() => {
            location.state
              ? navigate("/checkout", { state: location.state })
              : navigate("/");
            window.location.reload();
          }, 300);

          setIsButtonLoading(false);
        } else {
       
          toast.warn(response.data.msg, {
            position: "top-right",
          });
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        toast.warn(err, {
          position: "top-right",
        });
      
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear email error when typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(""); // Clear password error when typing
  };

  return (
    <React.Fragment>
        <section className="county-section h-screen">
          <div className="row p-3 rounded-2xl  flex flex-wrap justify-center">
            <div className="w-full  max-w-xl sm:h-[50%] dark:bg-[#1c1c1c] bg-white aspect-w-1 aspect-h-1 rounded-2xl px-6 md:px-12 py-8">
              <h1 className="text-4xl  text-skyBlue-300 font-semibold leading-[1.15] mb-5 text-center">
                Signin
              </h1>
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium dark:text-white text-gray-600"
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    className={`mt-1 p-2 border-[2px] dark:text-white  ${
                      emailError
                        ? "border-red-500 ring ring-red-300"
                        : " dark:border-[#676764] dark:bg-transparent   border"
                    } rounded-xl w-full focus:outline-none  bg-white 
                    `}
                    placeholder="user@example.com"
                  />
                  {emailError && (
                    <p className="text-red-500 text-sm mt-1">{emailError}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block text-sm  dark:text-white font-medium text-gray-600"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className={`mt-1 p-2 border-[2px] dark:text-white  ${
                      emailError
                        ? "border-red-500 ring ring-red-300"
                        : " dark:border-[#676764] dark:bg-transparent   border"
                    } rounded-xl w-full focus:outline-none  bg-white 
                    `}
                    placeholder="Enter your Password"
                  />
                  {passwordError && (
                    <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className={`button font-bold py-2 px-4 rounded-md hover:shadow-xl w-[100%] ${
                    loading
                      ? "bg-[#F5F5F5] text-black"
                      : "bg-skyBlue-300 hover:bg-skyBlue-300 text-white "
                  } flex items-center justify-center`}
                >
                  {loading ? (
                    <InfinitySpin
                    color="#00c4ff"
                      width="50"
                    ></InfinitySpin>
                  ) : (
                    "Login"
                  )}
                </button>
              </form>
              <Link to="/forgot-password">
                <div className="text-center flex items-center justify-center cursor-pointer mt-2">
                  <span className=" dark:text-white hover:text-skyBlue-500 underline text-sm">
                    Forgot Password?
                  </span>
                </div>
              </Link>
              <Link to="/sign-up">
                <div className="mt-2 text-center flex items-center justify-center cursor-pointer ">
                  <span className=" dark:text-white hover:text-[#626c6c] text-sm">
                    Don't have an account?
                    <span className="underline hover:text-skyBlue-400">
                      {" "}
                      Sign up
                    </span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <ToastContainer />
        </section>
    </React.Fragment>
  );
};

export default Layout(Login);
