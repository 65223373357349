import React, { useEffect, useState } from "react";
import "./style.css";
import Dashboard from "../Myaccount/Tabs/Dashboard";
import MyProfile from "../Myaccount/Tabs/UserInfo";
import ChangePassword from "../Myaccount/Tabs/ChangePassword";
import MyBookings from "../Myaccount/Tabs/MyBookings";
import { Link, useNavigate } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import { callApi } from "../../Utils/api";

const SideBar = [
  {
    id: 1,
    name: "Dashboard",
    product_slug:"dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-7 h-7 "
      >
        <path
          fill-rule="evenodd"
          d="M2.25 13.5a8.25 8.25 0 0 1 8.25-8.25.75.75 0 0 1 .75.75v6.75H18a.75.75 0 0 1 .75.75 8.25 8.25 0 0 1-16.5 0Z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M12.75 3a.75.75 0 0 1 .75-.75 8.25 8.25 0 0 1 8.25 8.25.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V3Z"
          clip-rule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: "My Profile",
    product_slug:"profile",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-7 h-7"
      >
        <path
          fill-rule="evenodd"
          d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
          clip-rule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 3,
    name: "My Booking",
    product_slug:"booking",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-7 h-7 "
      >
        <path
          fill-rule="evenodd"
          d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375Zm9.586 4.594a.75.75 0 0 0-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.116-.062l3-3.75Z"
          clip-rule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "Change Password",
    product_slug:"change-password",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-7 h-7"
      >
        <path
          fill-rule="evenodd"
          d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
          clip-rule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 5,
    name: "Logout",
    icon: <IoMdLogOut className="text-2xl" />,
  },
];

const CommonLayout = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const getUserData = localStorage.getItem("user" || null);
  const userData = JSON.parse(getUserData || null);
  const [userDetails, setUserDetails] = useState(userData);
  const navigate = useNavigate();
  const [userInfo , setUserInfo] = useState("")
const [ isLoad, setIsLoad] = useState(false)

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId == 5) {
      LogoutUser();
    }
  };



  const LogoutUser = () => {
    window.localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setIsLoad(true);
    if (userData?.id) {
      callApi(`user/${userData?.id}`, {}, "get")
        .then((response) => {
          const resData = response.data.data;
          setUserInfo(resData);
          setIsLoad(false);
        })
        .catch((err) => {
  
        });
    }
  };

  return (
    <div className="dark:border-neutral-700 rounded-2xl bg-white dark:bg-neutral-800">
      <div className="container  ">
        <div className="mt-10 md:my-2 px-4 space-y-6">
          <div className="flex flex-wrap items-cener md:items-start gap-4 p-3 ">
            <img
              className="bg-gray-400 rounded-md w-44"
              src={userInfo?.avatar}
              alt=""
            />
            <div>
              <h4 className="text-md mb-4 capitalize pb-2 border-b-[1px] border-b-gray-300 md:text-3xl lg:text-3xl dark:text-white font-semibold">
                {userData?.firstname} {userData?.lastname}{" "}
              </h4>
             
              <div className="flex  mb-4 pb-2 dark:text-white  border-b-[1px] border-b-gray-300  items-center gap-2 text-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 p-1 border border-skyBlue-300 rounded-md  text-skyBlue-300"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>

                <p className="text-[16px]">{userData?.email}</p>
              </div>
            </div>
          </div>

          <div className="mt-2 lg:border-b-[2px] lg:border-skyBlue-300 opacity-50 "></div>
          <div className="lg:flex gap-4">
            <div className="lg:border-r-[2px]  lg:w-1/5 mt-0 space-y-0  overflow-hidden  border-skyBlue-300">
              <div className="w-full py-3 dark:ring-neutral-700  dark:bg-neutral-800  ">
                <div className="sidebar-menus border-skyBlue-100">
                  <ul className="flex lg:flex-col text-white">
                    {SideBar.map((item, index) => (
                      <li
                        onClick={() => handleTabClick(item.id)}
                        key={item.id}
                        className={`${
                          activeTab === item.id
                            ? " m-1  bg-skyBlue-300 hover:bg-skyBlue-300 cursor-pointer text-white px-3 rounded-md py-2 flex items-center gap-2"
                            : " m-1 dark:text-white text-black px-3 rounded-md cursor-pointer py-2 flex items-center gap-2"
                        } `}>
                        {item.icon}
                        <Link
                        to={{ pathname: `/Myaccount/${item.product_slug}`,}}>
                        <span className="md:block hidden"> {item.name} </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-3 lg:w-4/5 py-6">
              <div className="content">
                {activeTab === 1 && <Dashboard />}
                {activeTab === 2 && (
                  <MyProfile
                    userData={userDetails}
                    handleUpdateUser={(event) =>
                      setUserDetails({ ...userDetails, ...event })
                    }
                  />
                )}
                {activeTab === 3 && <MyBookings />}
                {activeTab === 4 && <ChangePassword />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
