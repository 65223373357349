import React, { useState, useEffect } from "react";
// import ButtonPrimary from "../../../shared/Button/ButtonPrimary";

import { callApi } from "../../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../../Components/Buttons/ButtonPrimary";


const ChangePassword = () => {
  const  navigate = useNavigate();
  const getData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const validator = new SimpleReactValidator({
    messages: {
      in: "Passwords do not match.",
    },
    validators: {
      passwordMatch: {
        message: "The password and confirmation do not match.",
        rule: (val, params, validator) => {
          return val === userData.password;
        },
        required: true,
      },
    },
  });

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setErrorMessage({ ...errorMessage, [e.target.name]: " " });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      setLoading(true);
      const res = await callApi(
        `user/update_password/${getData?.id}`,
        userData,
        "POST"
      );
      if (res?.data?.msg && res?.data?.status == 200) {
        // toast.success(res?.data?.msg);
        setLoading(false);
        toast.success(res?.data?.msg, {
          position: "top-right"
        });
        setErrorMessage(" ");
        navigate('/');
      }else {
        setLoading(false);
        toast.success(res?.data?.msg, {
          position: "top-right"
        });
        setErrorMessage(" ");
        navigate('/');
      }
      if (res?.data?.errors) {
        setLoading(false);
        Object.keys(res?.data?.errors).map((key) =>
          setErrorMessage({ [key]: res?.data?.errors?.[key]?.[0] })
        );
      }
    } else {
      setLoading(false);
      validator.showMessages();
      setErrorMessage(validator.errorMessages);
    }
  };

 
  return (
    <div className="container">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        {Object?.keys(userData)?.map((key) => {
          const getKey = () => {
            switch (key) {
              case "current_password":
                return "Current";
                break;

              case "password":
                return "New";

              case "password_confirmation":
                return "Confirm";
            }
          };
          return (
            <div className="flex flex-col">
              <label htmlFor={key} className="text-[#111827] text-sm ">
                {getKey()} Password
              </label>
              <input
                type="password"
                name={key}
                id={key}
                className="mt-1 p-2 dark:text-white border dark:border-[#676764] dark:bg-transparent 
                rounded-md w-full focus:outline-none focus:border-gray-300"
                value={userData[key]}
                onChange={handleInput}
              />

              {validator.message(
                key,
                userData[key],
                key === "password_confirmation"
                  ? "required|passwordMatch"
                  : "required"
              )}
              {
                errorMessage && (
                  <p className="text-red-500 p-1">{errorMessage[key]}</p>
                ) 
              }  
            </div>
          );
        })}
      </div>

      
        <ButtonPrimary className="max-w-xl md:w-1/2 my-3"  onClick={handleSubmit}> { loading ?<InfinitySpin color="#00c4ff"  width="50"></InfinitySpin> : 'Change Password'}</ButtonPrimary>
     
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
