import React from "react";
import CommonLayout from "../MycommonLayout/CommonLayout.jsx";
import Layout from "../../Layout/index.jsx";

const Myaccount = () => {
  return (
    <React.Fragment>
      <section className="pb-10 home-Section">
        <div className="  rounded-xl ">
          <CommonLayout />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Layout(Myaccount);
