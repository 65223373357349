import React, { useState, ChangeEvent } from "react";

interface SimpleOption {
  name: string;
  id: number;
}

interface DropdownProps {
  label: string;
  value: any;
  options: SimpleOption[] | null | undefined; // Allow options to be null or undefined
  name: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  time: string;
}

const CountyDropdown: React.FC<DropdownProps> = ({
  label,
  value,
  options,
  onChange,
  name,
}) => {
  return (
    <div className="">
      <label className="mb-1 pb-1 block text-white dark:text-neutral-300 text-sm font-normal ">
        {label}<span className="text-[#CB3C5F]"> *</span>
      </label>
      <select
        value={value}
        name={name}
        onChange={onChange}
        className=" w-full bg-transparent border text-[#b6b8c9] border-[#262625] rounded-md py-2 px-3 focus:outline-none text-sm ">
        <option>Select {name}</option>
        {options &&
          options.map((option: any) => {
            return (
              <option
                key={option.id}
                value={option.id}
                className="sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                {name === "Country" ? option.pickup_address : option.name}{" "}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default CountyDropdown;
