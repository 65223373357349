import React, { useState } from "react";
import Select from "react-select";

const PickupDropdown = ({ label, options, onChange }) => {
  const [selectValue, setSelectvalue] = useState("");


  const handleChange = (selectedOption) => {
    const selectedPickupId = selectedOption.value;
    setSelectvalue(selectedOption);
    onChange(selectedOption);
  };

  const data = options.map((item) => ({
    name: `${item.name}`,
    value: `${item.id}`,
  }));

  const formatOptionLabel = ({  name }) => (
    <div className="grid grid-cols-2  py-2 place-content-between items-center justify-between">
      <div className="flex  items-center">
        <span className="w-full text-ellipsis">{name}</span>
      </div>
    </div>
  );


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      borderRadius: "8px",
      borderColor: "#ffffff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
      zIndex: "777",
      width:'100%',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ffff" : "#ffff",
      color: state.isSelected ? "#000" : "#000",
      borderColor: state.isSelected ? "#fff" : "#000",
      padding: "8px",
      width:'100%',
      zIndex: "888",
      "&:hover": {
        backgroundColor: state.isSelected ? "#f3f4f6" : "#f3f4f6",
        color: state.isSelected ? "#000" : "#000",
        borderColor: state.isSelected ? "#fff" : "#00c4ff",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%", 
      position: "absolute", 
      zIndex: "9999", 
    }),
  };
  

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: "#fff",
  //     borderRadius: "8px",
  //     borderColor: "#ffffff",
  //     boxShadow: "#00c4ff",

  //     "&:hover": {
  //       borderColor: "#00c4ff",
  //       border: "12px",
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#ffff" : "#ffff",
  //     color: state.isSelected ? "#000" : "#000",
  //     borderColor: state.isSelected ? "#fff" : "#000",
  //     padding: "8px",

  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#f3f4f6" : "#f3f4f6",
  //       color: state.isSelected ? "#000" : "#000",
  //       borderColor: state.isSelected ? "#fff" : "#00c4ff",
  //     },
  //   }),
  // };


  return (
    <div className="mb-4">
      <Select
        label={label}
        options={data}
        isSearchable={false}
        formatOptionLabel={formatOptionLabel}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
};

export default PickupDropdown;
