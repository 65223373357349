
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Page Not Found</h2>
        <p className="text-lg text-gray-600">Sorry, the page you're looking for doesn't exist.</p>
        <div className="flex justify-center">
                <Link
                to='/'
                  className="px-9 py-[8px] text-[#999] my-4 underline rounded-md"
                  
                >
                  Back to Home
                </Link>
              </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
