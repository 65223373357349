// SwitchDarkMode.js
import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { UseTheme } from "./MainToggle";

const SwitchDarkMode = ({ className = "" }) => {
  const { themeMode, toggleThemeMode } = UseTheme();
  if (!themeMode) {
    return null;
  }
  const isDarkMode = themeMode === "dark";
  return (
    <span onClick={toggleThemeMode} className={className}>
      {isDarkMode ? (
        <div className="cursor-pointer hover:border border-[#0e1010] bg-[#0e1010] w-8 h-8 md:w-12 md:h-12 flex items-center justify-center rounded-full hover:bg-transparent ">
          <FaSun className="md:w-6 md:h-6 w-4 h-4  rounded-full text-[#374151] dark:text-[#667387] " />
        </div>
      ) : (
        <div className="cursor-pointer hover:border border-[#f3f4f6]  w-8 h-8 md:w-12 md:h-12 flex items-center justify-center rounded-full hover:bg-transparent hover:bg-white  bg-[#f3f4f6]    ">
          <FaMoon className="md:w-6 md:h-6 w-4 h-4 rounded-full text-[#374151] dark:text-[#374151]  " />
        </div>
      )}
    </span>
  );
};

export default SwitchDarkMode;
