import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../Utils/api";
import _ from "lodash";
import { CiCirclePlus } from "react-icons/ci";
import StripeContainer from "../../Components/StripeContainer";
import SimpleReactValidator from "simple-react-validator";
import { InfinitySpin } from "react-loader-spinner";
import Confetti from "react-confetti";

const Checkout = () => {
  const navigate = useNavigate("");
  const location = useLocation();
  const validator = new SimpleReactValidator({
    className: "text-danger",
  });
  const productDetails = location.state;
  const [Loading, setLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ActiveTab, setActiveTab] = useState(1);
  const [LoginDetail, setLoginDetail] = useState({});
  const [SignupDetail, setSignupDetail] = useState({});
  const [num, setNum] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [couponDataAmount, setCouponDataAmount] = useState("");
  const [UserData, setUserData] = useState("");
  const [validateMsgEmail, setValidateEmailMsg] = useState(false);
  const [promo_code, setPromocode] = useState({});
  const [couponCelebration, setcouponCelebration] = useState(false);
  const [couponSuccess, setcouponSuccess] = useState(false);
  const [loginError, setLoginError] = useState({});
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: UserData?.email ? UserData.email : "",
    phone: "",
    description: "",
    user_id: UserData ? UserData?.id : " ",
    coupon_id: 1,
    newsletter_status: true,
    discount: Number(couponDataAmount) ? Number(couponDataAmount) : 0,
    cartItems: [
      {
        grandTotal: total?.toString(),
        totaLPrice: total?.toString(),
      },
    ],
  });

  const handleNext = () => {
    setActiveTab((prevActiveStep) => prevActiveStep + 1);
  };

  //---------  PASSENGER INFORMATION  ------------//

  const [pasData, setPasData] = useState([]);
  const [pasError, setPasError] = useState([]);

  const passangeDetails = {};

  pasData.forEach((data, index) => {
    passangeDetails[`attendee_name[${index + 1}]`] = data.attendee_name;
    passangeDetails[`attendee_phone[${index + 1}]`] = data.attendee_phone;
  });

  useEffect(() => {
    setNum(productDetails?.booking?.qty);
    setGrandTotal(productDetails?.booking?.totaLPrice);
  }, []);

  useEffect(() => {
    let userDetails = {};
    if (localStorage.getItem("user") != "undefined") {
      userDetails = JSON.parse(localStorage.getItem("user"));
    }
    if (!_.isEmpty(userDetails)) {
      setUserData(userDetails);
      setState((prevState) => ({
        ...prevState,
        email: userDetails?.email ? userDetails?.email : "",
        user_id: userDetails.id ? userDetails.id : "",
        firstname: userDetails?.firstname ? userDetails?.firstname : "",
        lastname: userDetails?.lastname ? userDetails?.lastname : "",
      }));
    }
  }, []);

  useEffect(() => {
    const getCartItems = JSON.parse(localStorage.getItem("cartItems"));
    setCartItems(getCartItems);
    setState((prevState) => ({
      ...prevState,
      cartItems: getCartItems,
    }));

    // get Total
    const initialValue = 0;
    const totalAdd = getCartItems?.reduce(
      (accumulator, current) => accumulator + current?.price * current?.qty,
      initialValue
    );

    setState((prevState) => ({
      ...prevState,
      cartItems: [
        {
          ...prevState.cartItems[0],
          grandTotal: totalAdd.toString(),
          totaLPrice: totalAdd.toString(),
        },
      ],
    }));
    setTotal(totalAdd);
    setGrandTotal(totalAdd);
  }, []);

  // ------------- 1ST TAB FUNCTIONS -------------- //

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginDetail({ ...LoginDetail, [name]: value });
    setErrorMessage({ ...ErrorMessage, [name]: " " });
  };

  const handleLoginSubmit = () => {
    if (validator.allValid()) {
      setLoading(true);
      callApi(`user/login`, LoginDetail, "post")
        .then((response) => {
          if (response?.data?.status === 200) {
            console.log(response?.data?.user, "resp");
            setState(response?.data?.user);
            localStorage.setItem("token", response?.data?.token);
            localStorage.setItem("isGuest", false);
            localStorage.setItem("user", JSON.stringify(response?.data?.user));
            setLoading(false);
            handleNext();
          } else if (response?.data?.status === 500) {
            const { errors, msg } = response.data;
            console.log(errors);
            setLoginError({
              ...loginError,
              errors,
              msg,
            });
            // if (errors) {
            //   Object.keys(errors).forEach((field) => {
            //     errors[field].forEach((errorMsg) => {
            //       toast.error(`${field}: ${errorMsg}`, {
            //         position: "top-right",
            //       });
            //     });
            //   });
            setLoading(false);
            // }
          } else {
            toast.warn(response.data.msg, {
              position: "top-right",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.warn(err, {
            position: "top-right",
          });
        });
    } else {
      validator.showMessages();
      setErrorMessage(validator.errorMessages);
    }
  };

  // ------------- 2ND TAB FUNCTIONS -------------- //

  useEffect(() => {
    const getUserToken = localStorage.getItem("token");
    const getUserDetail = JSON.parse(localStorage.getItem("user"));
    const getCartItem = JSON.parse(localStorage.getItem("cartItems"));
    if (!getCartItem) {
      navigate("/");
    }
    if (getUserToken) {
      setActiveTab(2);
      setState((state) => ({
        ...state,
        email: getUserDetail?.email ? getUserDetail?.email : "",
        user_id: getUserDetail.id ? getUserDetail.id : "",
        firstname: getUserDetail?.firstname ? getUserDetail?.firstname : "",
        lastname: getUserDetail?.lastname ? getUserDetail?.lastname : "",
      }));
      setUserData(getUserDetail);
    }
    // console.log("get userDetail", getUserDetail);
  }, []);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    setErrorMessage({ ...ErrorMessage, [name]: "" });
  };

  const handleUserSubmit = () => {
    if (validator.allValid()) {
      setLoading(false);
      handleNext();
    } else {
      validator.showMessages();
      setErrorMessage(validator.errorMessages);
    }
  };

  // ------------- 3RD TAB FUNCTIONS -------------- //

  useEffect(() => {
    const initialArray = Array(num).fill({
      attendee_phone: "",
      attendee_name: "",
    });
    setPasData(initialArray);
  }, [num, productDetails]);

  const validatePhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  // Handle passanger details change
  const handlePassangerChange = (event, index) => {
    const { name, value } = event.target;
    // Make a copy of the current form data
    const updatedFormData = [...pasData];
    const updatedFormError = [...pasError];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    updatedFormError[index] = { ...updatedFormError[index], [name]: "" };
    setPasData(updatedFormData);
    setPasError(updatedFormError);
  };

  // Validate passanger submit
  const validatePassangerDateils = () => {
    const newErrors = [];
    pasData.forEach((data, index) => {
      const fieldErrors = {};
      if (!data.attendee_name) {
        fieldErrors.attendee_name = "Name is required";
      }
      if (!data.attendee_phone) {
        fieldErrors.attendee_phone = "Phone is required";
      } else if (!validatePhone(data.attendee_phone)) {
        fieldErrors.attendee_phone = "Invalid phone number";
      }

      newErrors[index] = fieldErrors;
    });

    setPasError(newErrors);
    return newErrors.every(
      (fieldError) => Object.keys(fieldError).length === 0
    );
  };

  // Handle passanger submit
  const handlePassengerSubmit = () => {
    if (validatePassangerDateils()) {
      setLoading(false);
      handleNext();
    }
  };

  // ------------- 4TH TAB FUNCTIONS -------------- //

  const handleSignUpChange = (e) => {
    const { name, value } = e.target;
    setSignupDetail({ ...SignupDetail, [name]: value });
    setErrorMessage({ ...ErrorMessage, [name]: "" });
  };

  const handleSignUpSubmit = () => {
    const payload = {
      firstname: SignupDetail?.firstname,
      lastname: SignupDetail?.lastname,
      email: SignupDetail?.email,
      password: SignupDetail?.password,
    };
    if (validator.allValid()) {
      if (SignupDetail?.password !== SignupDetail?.confirm_password) {
        toast.error("Password Do Not Match");
      } else {
        callApi(`user/register`, payload, "post")
          .then((response) => {
            let errstatus = response.data;
            console.log("response", response);
            if (errstatus.token) {
              toast.success("Registration Successfully", {
                position: "top-right",
              });
              setLoading(false);
              localStorage.setItem("token", response?.data?.token);
              localStorage.setItem("isGuest", false);
              localStorage.setItem(
                "user",
                JSON.stringify(response?.data?.userData)
              );
              setState(response?.data?.userData);
              setActiveTab(2);
              setSignupDetail(" ");
            } else {
              toast.warn(response.data.msg, {
                position: "top-right",
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("isError", err);
            toast.warn(err, {
              position: "top-right",
            });
            setLoading(false);
          });
      }
    } else {
      validator.showMessages();
      setErrorMessage(validator.errorMessages);
    }
  };

  // ---------------- RIGHT SIDE FUNCTION ------------------- //

  let incNum = () => {
    let dummyCartItemInc = cartItems;
    if (dummyCartItemInc[0].qty < dummyCartItemInc[0].assigned_seats) {
      setNum(Number(num) + 1);
      dummyCartItemInc[0].qty = dummyCartItemInc[0].qty + 1;
      console.log("dumy", dummyCartItemInc[0]);
      dummyCartItemInc?.passengers?.push({
        id: dummyCartItemInc.passengers.length + 1,
        name: "",
        mobile: "",
      });

      const initialValue = 0;
      const totalAdd = cartItems?.reduce(
        (accumulator, current) => accumulator + current.price * current.qty,
        initialValue
      );

      setTotal(totalAdd);
      setGrandTotal(Number(totalAdd) - Number(couponDataAmount));
      setCartItems([...dummyCartItemInc]);
      setState((state) => ({
        ...state,
        cartItems: [
          {
            ...state?.cartItems?.[0],
            totaLPrice: total?.toString(),
            grandTotal: totalAdd?.toString(),
          },
        ],
      }));
    }
  };

  let decNum = (e) => {
    if (num > 1) {
      let dummyCartItemDec = cartItems;
      if (dummyCartItemDec[0].qty > 1) {
        // Decrease the quantity
        const updatedQty = dummyCartItemDec[0].qty - 1;
        dummyCartItemDec[0].qty = updatedQty;

        // Update state with the decreased quantity
        setNum(Number(num) - 1);
        console.log(
          "-------------->>>",
          dummyCartItemDec[0]?.passengers &&
            dummyCartItemDec[0]?.passengers.length >= 1
        );
        if (
          dummyCartItemDec[0]?.passengers &&
          dummyCartItemDec[0]?.passengers.length >= 1
        ) {
          // Remove the last passenger
          dummyCartItemDec[0].passengers.splice(-1);
        }
        // Calculate the total amount
        const initialValue = 0;
        const totalAdd = dummyCartItemDec?.reduce(
          (accumulator, current) => accumulator + current.price * current.qty,
          initialValue
        );
        setTotal(totalAdd);
        setGrandTotal(Number(totalAdd) - Number(couponDataAmount));
        setCartItems([...dummyCartItemDec]);
        // Update total and state
        setState((state) => ({
          ...state,
          cartItems: [
            {
              ...state?.cartItems?.[0],
              grandTotal: totalAdd?.toString(),
              totaLPrice: total?.toString(),
            },
          ],
        }));
        if (couponDataAmount) {
          HandleCoupon(e, totalAdd?.toString());
        }
      }
    }
  };

  // Check Coupon code
  const HandleCoupon = (e, grand_total = false) => {
    e.preventDefault();
    if (promo_code === "" || promo_code === undefined) {
    } else {
      callApi(
        `coupon_code`,
        {
          promo_code: promo_code,
          cart_amount: grand_total
            ? grand_total
            : state?.cartItems[0]?.grandTotal,
        },
        "post"
      )
        .then((res) => {
          let msg = res.data.msg;
          if (res.data.status === 200) {
            setCouponDataAmount(res ? res.data?.data?.value : 0);
            setGrandTotal(parseInt(total) - parseInt(res.data?.data?.value));
            setcouponCelebration(true);
            setcouponSuccess(true);
            setTimeout(() => {
              setcouponCelebration(false);
            }, 4500);
            setState((prevState) => ({
              ...prevState,
              discount: Number(res.data?.data?.value),
              cartItems: [
                {
                  ...prevState.cartItems[0],
                  grandTotal:
                    parseInt(total) -
                    parseInt(res.data?.data?.value).toString(),
                  totaLPrice: total.toString(),
                },
              ],
            }));
            toast.success(msg, {
              position: "top-right",
            });
          } else {
            setcouponSuccess(false);
            toast.warn(msg, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          console.log("isError ======>", err);
          toast.warn("The coupon code field is required", {
            position: "top-right",
          });
        });
    }
  };

  //Coupon code already applied
  const HandleCouponApplied = (e) => {
    e.preventDefault();
  };

  // ---------------- LEFT SIDE CONTENT ------------------- //

  function SignUp() {
    return (
      <div className="flex  items-center justify-center ">
        <div className="p-4 md:p-6 md:px-6  md:py-6  rounded-lg w-full md:max-w-lg ">
          <h1 className="text-md md:text-xl   dark:text-[#D8DBDE] font-semibold border-b border-gray-200 pb-4 mb-4">
            Sign Up
          </h1>
          <div>
            <div className="mb-4">
              <label
                for="firstname"
                className="block  text-xs md:text-sm font-medium text-gray-400"
              >
                FIRSTNAME<span className="text-red-500">*</span>
              </label>
              <input
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                id="firstname"
                placeholder="Enter firstname"
                value={SignupDetail?.firstname}
                onChange={handleSignUpChange}
                name="firstname"
                type="text"
              />
              {validator.message(
                "firstname",
                SignupDetail?.firstname,
                "required",
                {
                  className: "text-red-500 ",
                }
              )}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.firstname}
              </div>
            </div>
            <div className="mb-4">
              <label
                for="lastname"
                className="block  text-xs  md:text-sm font-medium text-gray-400"
              >
                LASTNAME<span className="text-red-500">*</span>
              </label>
              <input
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                id="lastname"
                placeholder="Enter lastname"
                value={SignupDetail?.lastname}
                onChange={handleSignUpChange}
                name="lastname"
                type="text"
              />
              {validator.message(
                "lastname",
                SignupDetail?.lastname,
                "required",
                {
                  className: "text-red-500 ",
                }
              )}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.lastname}
              </div>
            </div>
            <div className="mb-4">
              <label
                for="email"
                className="block text-xs  md:text-sm font-medium text-gray-400"
              >
                E-MAIL<span className="text-red-500">*</span>
              </label>
              <input
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                id="email"
                placeholder="Enter email"
                value={SignupDetail?.email}
                onChange={handleSignUpChange}
                name="email"
                type="email"
              />
              {validator.message("email", SignupDetail?.email, "required", {
                className: "text-red-500 ",
              })}
              <div className="text-red-500 text-sm ">{ErrorMessage?.email}</div>
            </div>
            <div className="mb-6">
              <label
                for="password"
                className="block  text-xs  md:text-sm font-medium text-gray-400"
              >
                PASSWORD<span className="text-red-500">*</span>
              </label>
              <input
                className="dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1"
                id="password"
                onChange={handleSignUpChange}
                value={SignupDetail?.password}
                name="password"
                placeholder="Enter password"
                type="password"
              />
              {validator.message(
                "password",
                SignupDetail?.password,
                "required",
                {
                  className: "text-danger ",
                }
              )}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.password}
              </div>
            </div>
            <div className="mb-4">
              <label
                for="confirm_password"
                className="block  text-xs  md:text-sm font-medium text-gray-400"
              >
                CONFIRM PASSWORD<span className="text-red-500">*</span>
              </label>
              <input
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                id="confirm_password"
                placeholder="Enter confirm password"
                value={SignupDetail?.confirm_password}
                onChange={handleSignUpChange}
                name="confirm_password"
                type="password"
              />
              {validator.message(
                "confirm_password",
                SignupDetail?.confirm_password,
                "required",
                {
                  className: "text-red-500 ",
                }
              )}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.confirm_password}
              </div>
            </div>

            <button
              type="submit"
              onClick={handleSignUpSubmit}
              className={` font-medium text-sm py-3 px-4 rounded-md hover:shadow-xl w-[40%] ${
                Loading
                  ? "bg-black text-white"
                  : "bg-black hover:bg-skyBlue-300 text-white "
              } flex items-center justify-center`}
            >
              {Loading ? (
                <InfinitySpin color="#00c4ff" width="40"></InfinitySpin>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
          <div className="mt-4 flex justify-between items-center">
            {/* <Link to="/forgot-password">
              <p className="text-sm text-skyBlue-500 font-semibold hover:text-skyBlue-800">
                Forgot Password ?
              </p>
            </Link> */}
            <div
              className="cursor-pointer w-full hover:underline"
              onClick={() => {
                setActiveTab(1);
                setErrorMessage("");
              }}
            >
              <span className="flex items-center text-xs dark:text-[#D8DBDE]  text-gray-600">
                Already have an account ?{" "}
                <p className="text-skyBlue-300 hover:text-skyBlue-800">
                  {" "}
                  Log in
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function SignIn() {
    return (
      <div className="flex  items-center justify-center ">
        <div className="p-4 md:px-8 md:pb-8 md:pt-6  rounded-lg w-full md:max-w-lg ">
          <h1 className="text-md md:text-xl  font-semibold border-b  dark:text-[#D8DBDE] border-gray-200 pb-4 mb-4">
            Sign In
          </h1>
          <div>
            <div className="mb-6">
              <label
                for="email"
                className="block text-xs md:text-sm font-medium text-gray-400"
              >
                E-MAIL
              </label>
              <input
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 text-xs dark:focus-visible:ring-offset-1 mt-1`}
                id="email"
                placeholder="Enter email"
                value={LoginDetail?.email}
                onChange={handleLoginChange}
                name="email"
                type="email"
              />
              {validator.message("email", LoginDetail?.email, "required", {
                className: "text-red-500 ",
              })}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.email}
                {loginError?.msg}
              </div>
            </div>
            <div className="mb-6">
              <label
                for="password"
                className="block text-xs md:text-sm font-medium text-gray-400"
              >
                PASSWORD
              </label>
              <input
                className="dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1"
                id="password"
                onChange={handleLoginChange}
                value={LoginDetail?.password}
                name="password"
                placeholder="Enter password"
                type="password"
              />
              {validator.message(
                "password",
                LoginDetail?.password,
                "required",
                {
                  className: "text-danger ",
                }
              )}
              <div className="text-red-500 text-sm ">
                {ErrorMessage?.password}
                {loginError?.password}
              </div>
            </div>
            <div className="my-8">
              <button
                type="submit"
                onClick={handleLoginSubmit}
                className={` font-medium  text-sm  py-2 md:py-3 px-4 rounded-md hover:shadow-xl w-[40%] ${
                  Loading
                    ? "bg-black text-white"
                    : "bg-black hover:bg-skyBlue-300 text-white "
                } flex items-center justify-center`}
              >
                {Loading ? (
                  <InfinitySpin color="#00c4ff" width="40"></InfinitySpin>
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </div>
          <div className="mt-4 md:flex md:justify-between items-center">
            <Link to="/forgot-password">
              <p className="text-xs text-skyBlue-500 font-normal hover:text-skyBlue-800">
                Forgot Password ?
              </p>
            </Link>
            <div
              onClick={() => {
                setActiveTab(5);
                setErrorMessage("");
              }}
              className="cursor-pointer hover:underline"
            >
              <span className=" mt-2 md:mt-0 flex gap-1 items-center text-xs dark:text-[#D8DBDE]  text-gray-600">
                Don't have an account ?{" "}
                <p className="text-skyBlue-300 hover:text-skyBlue-800">
                  Sign up
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function BillingAddress() {
    return (
      <div className="flex  items-center justify-center ">
        <div className="p-4 md:p-6 md:px-6  md:py-6  rounded-lg w-full md:max-w-lg ">
          <h1 className="text-md md:text-xl   dark:text-[#D8DBDE] font-semibold border-b border-gray-200 pb-4 mb-4">
            Checkout{" "}
            <span className="text-sm  text-gray-500 font-medium">
              {" "}
              (Billing Address)
            </span>
          </h1>
          <div>
            <div className=" mb-4">
              <label
                for="email  "
                className="block  text-xs  md:text-sm  text-gray-400"
              >
                E-MAIL<span className="text-red-500">*</span>
              </label>
              <input
                // className={`flex h-10 w-full rounded-md border bg-background px-3 py-2 text-sm ring-offset-none file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus:ring-skyBlue-500 disabled:cursor-not-allowed disabled:opacity-50 mt-1
                // ${
                //   ErrorMessage ? "border-red-500 focus:ring-red-500 ring-red-500" : "border-skyBlue-500"
                // } `}
                className={`dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                id="email"
                placeholder="Enter email"
                value={state.email}
                onChange={handleUserChange}
                name="email "
                type="text"
              />
              {validator.message("email", state?.email, "required", {
                className: "text-red-500 ",
              })}
              <div className="text-red-500 text-sm ">{ErrorMessage?.email}</div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="md:mb-6">
                <label
                  for="firstname"
                  className="block  text-xs  md:text-sm text-gray-400"
                >
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  className="dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1"
                  id="firstname"
                  onChange={handleUserChange}
                  value={state?.firstname}
                  name="firstname"
                  placeholder="Enter firstname"
                  type="firstname"
                />
                {validator.message("firstname", state?.firstname, "required", {
                  className: "text-red-500  ",
                })}
                <div className="text-red-500  text-sm ">
                  {ErrorMessage?.firstname}
                </div>
              </div>

              <div className="mb-6">
                <label
                  for="lastname"
                  className="block  text-xs  md:text-sm text-gray-400"
                >
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  className="dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1"
                  id="lastname"
                  onChange={handleUserChange}
                  value={state?.lastname}
                  name="lastname"
                  placeholder="Enter lastname"
                  type="lastname"
                />
                {validator.message("lastname", state?.lastname, "required", {
                  className: "text-red-500  ",
                })}
                <div className="text-red-500  text-sm ">
                  {ErrorMessage?.lastname}
                </div>
              </div>
            </div>

            <div className="mb-6">
              <label
                for="description"
                className="block  text-xs  md:text-sm text-gray-400"
              >
                ADDITIONAL INFORMATION (OPTIONAL)
              </label>
              <textarea
                className=" dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex  w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1"
                id="description"
                onChange={handleUserChange}
                value={LoginDetail?.description}
                name="description"
                placeholder="Enter description"
                type="text"
                rows="4"
                cols="50"
              ></textarea>
              {/* {validator.message(
                "description",
                state?.description,
                "required",
                {
                  className: "text-red-500  ",
                }
              )} */}
              {/* <div className="text-red-500  text-sm ">
                {ErrorMessage?.description}
              </div> */}
            </div>

            <div className="flex gap-2 my-6 items-start">
              <input className="mt-1" type="checkbox"></input>
              <p className="dark:text-[#D8DBDE] text-sm">
                {" "}
                Get updates from Event Bus about your upcoming events
              </p>
            </div>

            <button
              onClick={handleUserSubmit}
              type="submit"
              className={` w-40 bg-black text-sm  text-white text-center rounded-md py-2 md:py-3 px-4 `}
            >
              {Loading ? (
                <InfinitySpin
                  className="text-center"
                  color="#00c4ff"
                  width="50"
                ></InfinitySpin>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  function Checkout() {
    return (
      <div className="flex  items-center justify-center ">
        <div className=" p-4 md:p-6 md:px-6 md:py-6 rounded-lg w-full md:max-w-lg ">
          <h1 className="text-md md:text-xl  font-semibold border-b dark:text-[#D8DBDE] border-gray-200 pb-4 mb-6">
            Checkout
          </h1>
          <div>
            {pasData.map((passengerItem, i) => {
              return (
                <>
                  <div className="w-full flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <p className="text-white flex items-center justify-center text-xs bg-skyBlue-300 w-4 rounded-full">
                        {i + 1}
                      </p>
                      <p className="text-xs md:text-sm dark:text-[#D8DBDE]  text-gray-600">
                        Passenger Information
                      </p>
                    </div>
                    <button className="cursor-pointer">
                      <div className="text-gray-500 dark:text-[#D8DBDE] flex items-center">
                        <p>
                          <CiCirclePlus className="text-green-600" />
                        </p>
                        <p className=" text-xs md:text-sm" onClick={incNum}>
                          Add New Passenger
                        </p>
                      </div>
                    </button>
                  </div>

                  <div className=" mb-4">
                    <label
                      for="email"
                      className="block  text-xs md:text-sm text-gray-400"
                    >
                      PASSENGER NAME <span className="text-red-500">*</span>
                    </label>
                    <input
                      className={`mt-1 p-2 border ${
                        validateMsgEmail ? "border-red-500" : "border-gray-300"
                      }  focus:outline-none focus:border-gray-300-500
                      dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1
                      `}
                      pattern="[a-zA-Z]"
                      title="Only keyword are Allows"
                      placeholder="Enter name"
                      onChange={(e) => handlePassangerChange(e, i)}
                      name="attendee_name"
                      type="text"
                    />
                    {pasError[i] && pasError[i].attendee_name && (
                      <span className="text-sm pt-2 text-red-600">
                        {pasError[i].attendee_name}
                      </span>
                    )}
                  </div>

                  <div className=" mb-6">
                    <label
                      for=" attendee_phone"
                      className="block text-xs md:text-sm text-gray-400"
                    >
                      PASSENGER NUMBER <span className="text-red-500">*</span>
                    </label>
                    <input
                      className={` dark:bg-[#3B3B3B] dark:text-[#D8DBDE]  flex h-10 w-full rounded-md border border-input px-3 py-2 text-xs ring-offset-none  focus-visible:outline-none dark:focus:ring-[#3B3B3B] focus:ring-skyBlue-500 focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 dark:focus-visible:ring-offset-1 mt-1`}
                      id="attendee_phone"
                      placeholder="Enter phone number"
                      onChange={(e) => handlePassangerChange(e, i)}
                      name="attendee_phone"
                      type="text"
                    />
                    {pasError[i] && pasError[i].attendee_phone && (
                      <span className="text-sm pt-2 text-red-600">
                        {pasError[i].attendee_phone}
                      </span>
                    )}
                  </div>
                </>
              );
            })}
            <button
              onClick={handlePassengerSubmit}
              type="submit"
              className="w-40 bg-black  text-white text-center rounded-md py-2 md:py-3 px-4"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  function PaymentPage() {
    return (
      <div className="flex  items-center justify-center ">
        <div className=" p-4 md:p-6 md:px-6  md:py-6  rounded-lg w-full md:max-w-lg ">
          <h1 className="text-md md:text-xl   font-semibold border-b dark:text-[#D8DBDE] border-gray-200 pb-4 mb-6">
            Checkout
          </h1>

          <div className="mt-6">
            <div className="mt-3 ">
              <StripeContainer
                state={state}
                productDetails={{
                  ...productDetails,
                  updatedQty: num,
                  passangeDetails,
                }}
                newsLetter={state.newsletter_status}
                userDetails={LoginDetail}
                total={Number(productDetails?.booking?.totaLPrice)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // ---------------- RIGHT SIDE CONTENT ------------------- //

  function BookingDetail() {
    const formattedDate = moment(
      productDetails?.booking?.date,
      "YYYY-MM-DD"
    ).format("ddd Do YYYY");
    return (
      <>
        <body className="">
          <div class="container  mx-auto p-4 md:px-6 pt-6 md:pb-8">
            <h1 class="text-md md:text-xl  border-b dark:text-[#D8DBDE] border-gray-200 font-semibold pb-4 mb-4">
              Booking details
            </h1>
            <div class="flex  lg:flex-row items-start  md:mb-4">
              <img
                src={productDetails?.productdetails?.image}
                alt="Coldplay Concert"
                class="w-24 h-24 rounded-xl mr-4 lg:mr-8 mb-4 lg:mb-0"
              />
              <div class="">
                <p class="mb-2 md:mb-4 text-sm md:text-xl dark:text-[#D8DBDE] text-[#3D404B] font-semibold">
                  {productDetails?.productdetails?.name}
                </p>
                <div class="flex flex-wrap items-center md:gap-6">
                  <p class="dark:text-[#D8DBDE] text-[#3D404B] text-md md:text-xl font-semibold">
                    {productDetails?.productdetails?.price} €
                  </p>

                  <div
                    className={` ${
                      ActiveTab == 3 ? "block " : "hidden"
                    } flex border border-[#ECECED] rounded-md p-1 items-center`}
                  >
                    <button
                      onClick={decNum}
                      disabled={ActiveTab === 3 ? false : true}
                      class="px-3 rounded-md py-1 dark:text-[#D8DBDE] dark:bg-[#3B3B3B] bg-[#F1F2F5] focus:outline-none"
                    >
                      -
                    </button>
                    <input
                      type="text"
                      id="seats"
                      name="seats"
                      value={num}
                      class="w-12 dark:text-[#D8DBDE] dark:bg-transparent text-center focus:outline-none"
                    />
                    <button
                      disabled={ActiveTab === 3 ? false : true}
                      onClick={incNum}
                      class="px-3 rounded-md py-1 dark:text-[#D8DBDE] dark:bg-[#3B3B3B] bg-[#F1F2F5] focus:outline-none"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded-lg dark:text-[#D8DBDE] border-gray-300 mt-2 md:mt-9 max-w-md gap-4">
              <div class="flow md:flex py-2 px-4 md:px-6  dark:bg-[#3B3B3B]  bg-[#F7F8FA] rounded-t-lg items-center justify-between md:justify-start">
                <p class="md:w-52 w-auto text-sm dark:text-[#D8DBDE]  text-black font-semibold">
                  Event Date
                </p>
                <p class=" text-sm  dark:text-[#D8DBDE]  text-black">
                  {formattedDate}
                </p>
              </div>
              <div className="flow md:flex py-2 px-4 md:px-6  items-center justify-between md:justify-start">
                <p className="md:w-52 w-auto  text-sm dark:text-[#D8DBDE] text-black font-semibold">
                  Number of seats
                </p>
                <p className=" text-sm  dark:text-[#D8DBDE] text-[#62687D]">
                  {num}
                </p>
              </div>

              <div className="flow md:flex py-2 px-4 md:px-6 dark:bg-[#3B3B3B] bg-[#F7F8FA] items-center justify-between md:justify-start">
                <p className="md:w-52 w-auto  text-sm dark:text-[#D8DBDE] text-black font-semibold">
                  Price per seat
                </p>
                <p className="  text-sm  dark:text-[#D8DBDE] text-black">
                  {productDetails?.booking?.price} €
                </p>
              </div>
              <div className="flow md:flex py-2 px-4 md:px-6 items-center justify-between md:justify-start">
                <p className="md:w-52 w-auto  text-sm dark:text-[#D8DBDE] text-black font-semibold">
                  County From
                </p>
                <p className="  text-sm  dark:text-[#D8DBDE] text-black">
                  {productDetails?.booking?.county_name}
                </p>
              </div>
              <div className="flow md:flex py-2 px-4 md:px-6 dark:bg-[#3B3B3B] bg-[#F7F8FA] items-center justify-between md:justify-start">
                <p className="md:w-52 w-auto  text-sm dark:text-[#D8DBDE] text-black font-semibold">
                  Time
                </p>
                <p className=" text-sm  dark:text-[#D8DBDE] text-black">
                  {" "}
                  {productDetails?.booking?.time}
                </p>
              </div>
              <div className="flow md:flex py-2 px-4 md:px-6  items-center justify-between md:justify-start">
                <p className=" md:w-52 w-auto text-sm dark:text-[#D8DBDE] text-black font-semibold">
                  Pickup From
                </p>
                <p className="text-sm dark:text-[#D8DBDE] text-black">
                  {" "}
                  {productDetails?.booking?.town} {" , "}{" "}
                  {productDetails?.booking?.landmark}
                </p>
              </div>
            </div>
            <div
              className={` my-4 ${ActiveTab == 4 && 5 ? "hidden " : "block"} `}
            >
              <h2 class="text-md md:text-xl   pb-4 mb-5 w-full outline-none dark:text-[#D8DBDE] border-gray-200 border-b font-semibold">
                Apply Coupon
              </h2>

              <div class="mt-2 text-gray-500">
                <label
                  for="coupon_code"
                  class="block text-xs md:text-sm font-medium dark:text-[#D8DBDE]  text-gray-500"
                >
                  COUPON CODE
                </label>
                <div class="mt-1 flex rounded-md ">
                  <input
                    type="text"
                    id="coupon_code"
                    name="coupon_code"
                    placeholder="Enter Coupon Code"
                    onChange={(e) => setPromocode(e.target.value)}
                    readOnly={couponSuccess ? true : false}
                    className="
                dark:bg-[#3B3B3B] text-xs dark:text-[#D8DBDE] 
                 p-2 h-10 w-full md:w-80 border focus-visible:outline-none focus:ring-skyBlue-500 focus-visible:ring-ring focus-visible:ring-offset-2 rounded-l-md border-gray-300 focus:border-skyBlue-500"
                  />

                  {couponSuccess ? (
                    <button
                      disabled
                      onClick={HandleCouponApplied}
                      className="btn btn-primary text-white p-2  rounded-r-md bg-green-500 applyCouponApplied "
                    >
                      ✓ Coupon Applied
                    </button>
                  ) : (
                    <button
                      onClick={HandleCoupon}
                      class="items-center w-40 md:w-32 md:px-4 md:py-2 border border-transparent text-xs md:text-sm font-medium rounded-r-md text-white bg-skyBlue-300 focus:outline-none focus:ring-offset-gray focus:ring-offset-gray focus:ring-indigo"
                    >
                      Apply code
                    </button>
                  )}
                </div>
              </div>

              {couponCelebration ? (
                <Confetti
                  className="celebration"
                  style={{ width: "100%", height: "100vh", position: "fixed" }}
                />
              ) : null}
            </div>

            <div className="my-4">
              <h2 className=" text-md md:text-xl dark:text-[#D8DBDE] pb-4 mb-2  font-semibold text-black">
                Price detail
              </h2>
              <div className="dark:text-[#D8DBDE] text-[#3D404B] font-medium w-full md:w-4/5">
                <div className="w-full">
                  <div className=" border flex justify-between items-center rounded-t-lg  py-2">
                    <div className="py-1 px-4 text-sm ">Total</div>
                    <div className="text-right text-sm dark:text-[#D8DBDE] px-4">
                      {Number(total)} {"€"}
                    </div>
                  </div>
                  <div className=" border-l border-r border-t flex justify-between items-center py-2 ">
                    <div className="py-1 px-4 text-sm">Discount</div>
                    <div className="text-right text-sm dark:text-[#D8DBDE] px-4">
                      {" "}
                      {couponDataAmount ? couponDataAmount : 0} €
                    </div>
                  </div>
                  <div className=" border-l border-r border-t flex justify-between items-center py-2">
                    <div className="py-1 px-4 text-sm">Service charge</div>
                    <div className="text-right text-sm dark:text-[#D8DBDE] px-4">
                      0 €
                    </div>
                  </div>
                  <div className="border font-semibold  rounded-b-lg flex justify-between items-center py-2 text-black dark:bg-[#3B3B3B] dark:text-[#D8DBDE] bg-[#F7F8FA] ">
                    <div className="px-4 py-1 text-sm">Grand Total</div>
                    <div className="text-right text-sm  px-4">
                      {Number(GrandTotal)} {"€"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </>
    );
  }

  return (
    <>
      <section className=" rounded-md grid grid-cols-1 md:grid-cols-12  md:gap-2">
        <div className="col-span-12 md:col-span-5 m-4">
          {/* <div onClick={handlePrev}>Back</div> */}
          <div className="bg-white dark:bg-[#2B2B2B] rounded-xl ">
            {ActiveTab === 1 && SignIn()}
            {ActiveTab === 2 && BillingAddress()}
            {ActiveTab === 3 && Checkout()}
            {ActiveTab === 4 && PaymentPage()}
            {ActiveTab === 5 && SignUp()}
          </div>
        </div>
        <div className="col-span-12 md:col-start-6 md:col-span-7">
          <div className=" m-4 rounded-xl bg-white dark:bg-[#2B2B2B]">
            {BookingDetail()}
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default Layout(Checkout);
