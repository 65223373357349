import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { callApi } from "../../Utils/api";
import { InfinitySpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Layout";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [loading, setIsButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    email: "",
    type: "User",
    otp: " ",
    key: "",
  });
  const validateEmail = (input) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };


  const handleLogin = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Basic validation
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }
    // If validation passes, you can proceed with the login logic
    // For example, make an API call to authenticate the user
    // Simulating a successful login
    // Replace the following line with your actual login logic
    const isLoginSuccessful = true;
    if (isLoginSuccessful) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    callApi(
      `user/app/forgot-password`,
      { email: email, type: data?.type },
      "post"
    )
      .then((response) => {
        const resMsg = response?.data?.msg;
        if (response?.data?.status === 200) {
         
          // localStorage.setItem("token", response?.data?.token);
          // localStorage.setItem("isGuest", false);
          // localStorage.setItem("user", JSON.stringify(response?.data?.user));
          toast.success("OTP Sent Successfully", {
            position: "top-right",
          });
          setIsButtonLoading(false);
          setShow(true);
          // navigate("/reset_password");
        } else {
         
          setIsButtonLoading(false);
          toast.warn(resMsg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
     
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
    setEmailError("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleVerify = async () => {
    if (!data.otp.trim()) {
      toast.error("Please enter the OTP");
      return;
    }
    try {
      const response = await callApi(
        "user/app/verify-otp",
        { email: email, type: data?.type, otp: data?.otp },
        "post"
      );
      if (response.status === 200) {
        toast.success(response?.data?.msg);
        navigate("/reset_password", {
          state: {
            data: data,
            key: response?.data?.key,
          },
        });
      } else {
        toast.error( response?.response?.data?.msg);
      }
    } catch (error) {
      // Handle network errors or other exceptions
     
      toast.error("An error occurred. Please try again later.");
    }
  };
  


  return (
    <React.Fragment>
      <section className="county-section  h-screen">
        <div className="row p-4 rounded-2xl flex flex-wrap justify-center">
          <div className="w-full sm:w-[50%]  dark:bg-[#2B2B2B] bg-white aspect-w-1 rounded-2xl p-5">
            <h1 className="text-3xl   text-skyBlue-300 font-medium leading-[1.15] mb-5 text-center">
              Forgot Password
            </h1>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium  dark:text-white text-gray-600"
              >
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className={`mt-1 p-2 border dark:border-[#676764] dark:bg-transparent  ${
                  emailError ? "border-red-500" : "border-skyBlue-300"
                } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                placeholder="user@example.com"
              />
              {emailError && (
                <p className="text-red-500 text-sm mt-1">{emailError}</p>
              )}
            </div>

            {show && (
              <div className="mb-4">
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium  dark:text-white text-gray-600"
                >
                  Otp
                </label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  value={data?.otp}
                  onChange={handleChange}
                  className={`mt-1 p-2 border dark:border-[#676764] dark:bg-transparent  ${
                    data?.otp ? "border-skyBlue-500" : "border-skyBlue-300"
                  } rounded-md w-full focus:outline-none focus:border-skyBlue-300-500`}
                  placeholder="Enter OTP"
                />
                {/* {data?.otp && <p className="text-red-500 text-sm mt-1">{data?.otp}</p>} */}
              </div>
            )}

            {show ? (
              <>
                <ButtonPrimary
                  onClick={handleVerify}
                  className={`font-bold py-2 px-4 rounded-md w-[100%] ${
                    loading
                      ? "bg-[#F5F5F5] text-black"
                      : "ButtonPrimary bg-skyBlue-300 text-white "
                  } flex items-center justify-center`}
                >
                  {loading ? (
                    <InfinitySpin
                      className="text-white"
                      width="50"
                    ></InfinitySpin>
                  ) : (
                    "Verify OTP"
                  )}
                </ButtonPrimary>
              </>
            ) : (
              <>
                <ButtonPrimary
                  onClick={handleLogin}
                  className={`font-bold py-2 px-4 rounded-md w-[100%] ${
                    loading
                      ? "bg-[#F5F5F5] text-black"
                      : "ButtonPrimary bg-skyBlue-300 text-white "
                  } flex items-center justify-center`}
                >
                  {loading ? (
                    <InfinitySpin
                      className="text-white"
                      width="50"
                    ></InfinitySpin>
                  ) : (
                    "Continue"
                  )}
                </ButtonPrimary>
              </>
            )}

            <Link to="/login">
              <div className=" mt-4 text-center flex items-center justify-center cursor-pointer ">
                <span className="dark:text-skyBlue-300  hover:text-skyBlue-400 text-sm">
                  Remember your password?
                </span>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Layout(ForgotPassword);
