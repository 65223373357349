import react, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { callApi } from "../../../Utils/api";
import Skeleton from "react-loading-skeleton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../style.css";
import Logo from "../images/logo2.png";
import Icon1 from "../images/icon1.png";
import Icon2 from "../images/icon2.png";
import Icon3 from "../images/icon3.png";
import RatingModal from "../Rating";
import Modal from "react-modal";
// import { setegid } from "process";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../../Components/Buttons/ButtonPrimary";
import { InfinitySpin } from "react-loader-spinner";
import { FaArrowLeft } from "react-icons/fa";

const tabs = [
  // { label: "All Booking", value: "0" },
  { label: "Confirmed", value: "1" },
  // { label: "Pending", value: "3" },
];

const AccountSavelists = () => {
  const paras = useParams();

  let [categories] = useState(["Stays", "Experiences", "Cars"]);
  const [data, setData] = useState([]);
  const [orderDetails, setOrderDetails] = useState("");
  const [orderAttendee, setOrderAttendee] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [ticketData, setTicketData] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [LoadingData, setLoadingData] = useState(true);
  const [activeTab, setActiveTav] = useState("0");
  const [isRatingModal, setisRatingModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState("");

  console.log(paras, "param");

  // const navigate = useNavigate();

  const toggleRating = () => {
    setisRatingModal(!isRatingModal);
  };
  const filterData =
    activeTab == 0 ? data : data.filter((item) => item.status == activeTab);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails) {
      setUserDetails(userDetails);
      if (userDetails.id) {
        getData(userDetails.id);
      }
    } else {
      setLoadingData(false);
    }
  }, [showDetails]);

  const getData = async (id) => {
    setLoadingData(true);
    const res = await callApi(`get-booking/${id}`, {}, "get");
    if (res?.data) {
      setLoadingData(false);
      setData(res?.data?.data);
    } else {
      setLoadingData(false);
    }
  };

  function viewDetailsHandle(id) {
    window.scrollTo(0, 0);
    setShowDetails(true);
    setTimeout(async () => {
      const res = await callApi(
        `get-booking-detail/${userDetails.id}/${id}`,
        {},
        "GET"
      );
      
      if (res?.data) {
        setOrderDetails(res?.data?.data);
        setOrderAttendee(res?.data?.data?.attendees);
      
      }
    }, 100);
  }

  // useEffect(()=>{
  //   viewDetailsHandle()
  // } , [])

  function viewTicket(row) {
    window.scrollTo(0, 0);
    setTicketData(row);
    setShowTicket(true);
  }

  const goBackHandle = () => {
    setShowDetails(false);
    setData([]);
    setOrderDetails("");
    setOrderAttendee("");
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("travelmaster-ticket.pdf");
    });
  };

  const handleCancelOrder = async () => {
    try {
      const pay = new FormData();
      pay.append("booking_id", editData.id);
      pay.append("operator_id", editData.oprator_id);
      pay.append("user_id", editData.user_id);
      const res = await callApi("/send-cancel-request", pay, "POST");
      if (res?.status === 201) {
        const updatedBookings = data.filter(
          (booking) => booking.id !== data.id
        );
        toast.success("Cancel Request Has Been Submitted", {
          position: "top-right",
          autoClose: 5000,
        });
        setData(updatedBookings);
      } else {
        toast.error("Booking Request Not Submit", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error while deleting the booking:", error);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#000",
      borderRadius: 10,
    },
  };

  return (
    <div>
      <div className={`nc-AccountPage`} data-nc-id="AccountPage">
        <title>My Account || EventBus</title>

        {showTicket ? (
          <div className="h-screen p-5 dark:border-neutral-300  dark:bg-neutral-600 orderdetails">
            <div className="space-y-6 sm:space-y-8 ">
              <div className="flex justify-between">
                <ButtonPrimary
                  className="btn rounded-md btn-primary  "
                  onClick={() => setShowTicket(false)}
                  // onClick={goBackHandle}
                >
                  Go Back
                </ButtonPrimary>
                <ButtonPrimary
                  className="btn  my-2 rounded-md  btn-primary py-1 mr-2"
                  onClick={printDocument}
                >
                  Download Ticket
                </ButtonPrimary>
              </div>
              <div className=" ">
                <h2 className="text-3xl font-semibold">
                  Print or Screenshot Your Ticket
                </h2>
              </div>
              <div className="flex justify-center items-center">
                <div id="divToPrint" className="mt4 bg-[#0b0f19]">
                  <div className="printMainWrap">
                    <div className="printLogoSec">
                      <div className="templateHeader m-1">
                        <img src={Logo} />
                      </div>
                    </div>
                    <div className="printContect" style={{ padding: "10px" }}>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#00ffd0",
                          textAlign: "left",
                        }}
                      >
                        Save this E-ticket to your phone or print
                      </p>
                      <div
                        style={{
                          justifyContent: "center",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "1.5",
                                marginBottom: "5px",
                              }}
                            >
                              Hi
                              <strong
                                style={{
                                  color: "#00ffd0",
                                  fontWeight: "600",
                                }}
                              >
                                {ticketData?.attendee_name}
                              </strong>
                              ,
                            </p>
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "1.5",
                                marginRight: "10px",
                              }}
                            >
                              Your event booking is confirmed with us on Monday,
                              November 10th 2021 at 13:45.
                              {/* {' ' + orderDetails?.date_concert} */}
                            </p>
                          </div>
                          <img
                            src={
                              "data:image/png;base64," + ticketData?.ticket_qr
                            }
                            alt="Qr Code"
                            title="Qr Code"
                            width={100}
                            height={100}
                            style={{
                              background: "#fff",
                              margin: "auto",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                          <table style={{ fontSize: "12px" }}>
                            <tr>
                              <td
                                style={{
                                  width: "150px",
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                  borderBottom: "0px",
                                  borderRight: "0px",
                                }}
                              >
                                <div>Event Name</div>
                              </td>
                              <td
                                style={{
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                  lineHeight: "1.2",
                                  borderBottom: "0px",
                                }}
                              >
                                <div>{orderDetails?.event_name}</div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                  borderBottom: "0px",
                                  borderRight: "0px",
                                }}
                              >
                                <div>Passenger Name</div>
                              </td>
                              <td
                                style={{
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                  borderBottom: "0px",
                                }}
                              >
                                <div>{ticketData?.attendee_name}</div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                  borderRight: "0px",
                                }}
                              >
                                <div>Passenger Number</div>
                              </td>
                              <td
                                style={{
                                  border: "1px solid #00ffd0",
                                  padding: "5px 7px",
                                }}
                              >
                                <div>{ticketData?.attendee_number}</div>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={Icon1}
                            alt="No Alchohol"
                            style={{ width: "120px", marginRight: "5px" }}
                            className="ticketIcon"
                          />
                          <img
                            src={Icon2}
                            alt="Sarly"
                            style={{ width: "120px", marginRight: "5px" }}
                            className="ticketIcon"
                          />
                          <img
                            src={Icon3}
                            alt="Screenshot"
                            style={{ width: "120px" }}
                            className="ticketIcon"
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            scrollMarginBottom: "30px",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#00ffd0",
                              textAlign: "left",
                            }}
                          >
                            Note
                          </p>
                          <p style={{ fontSize: "11px", lineHeight: "1.3" }}>
                            N.B Bus Departs 45 mins after the event finishes.
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#00ffd0",
                              textAlign: "left",
                            }}
                          >
                            Terms & Conditions
                          </p>
                          <p style={{ fontSize: "11px", lineHeight: "1.3" }}>
                            Print or screenshot ticket to present to the driver
                            when boarding the bus. Powered by TCPDF
                            (wwwP.tcpdf.org) lease be at your Pick Up Point 15
                            mins before departure time .
                          </p>
                          <p
                            style={{
                              fontSize: "11px",
                              lineHeight: "1.3",
                              marginTop: "20px",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="dark:border-neutral-700  dark:bg-neutral-800 orderdetails-sec">
            <div className="">
              {showDetails === false ? (
                <>
                  {/* HEADING */}
                  <h2 className="text-2xl dark:text-[#D8DBDE] text-black  font-semibold">
                    My Bookings
                  </h2>
                  {/* <div className="booking-tabs ">
                    {tabs.map((item, index) => (
                      <button
                        key={item.label}
                        onClick={() => {
                          setActiveTav(item.value);
                        }}
                        className={`px-4 py-2 mx-2 ${
                          activeTab == item.value
                            ? " text-skyBlue-50 dark:text-skyBlue-50 border-b border-dashed dark:border-skyBlue-100 border-skyBlue-100   "
                            : " text-black border-b border-black dark:border-white border-dashed  dark:text-white"
                        } focus:outline-none`}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div> */}

                  {LoadingData ? (
                    <>
                      <Skeleton height={50} count={10} />
                    </>
                  ) : data?.length <= 0 ? (
                    <>
                      <div className="my-5 text-center">
                        <div style={{ marginTop: "100px" }}></div>
                        <h5 className="text-1xl dark:text-[#D8DBDE] font-semibold">
                          There Is No Order Yet
                        </h5>
                        <div style={{ marginTop: "100px" }}></div>
                      </div>
                    </>
                  ) : data?.length !== 0 ? (
                    <>
                      <div className="" />
                      <div className="flex my-2 md:my-4 flex-col md:flex-row responsive-table overflow-y-scroll">
                        <table className="productDetailsTable border-[1px] border-skyBlue-300 dark:border-neutral-300 w-full">
                          <thead className="rounded-lg   position-sticky">
                            <tr className="bg-skyBlue-200 text-white dark:bg-skyBlue-300  dark:text-white  ">
                              <th className="border border-[#00a9ff]">
                                Event{" "}
                              </th>
                              <th className="border border-[#00a9ff]">
                                Booking Id
                              </th>

                              <th className="border border-[#00a9ff]">
                                Event Date
                              </th>

                              <th className="border border-[#00a9ff]">
                                Booking Seats
                              </th>
                              <th className="border border-[#00a9ff]">
                                Booking Status
                              </th>

                              <th className="border border-[#00a9ff]">Fare</th>
                              <th className="border border-[#00a9ff]">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="border-[1px] border-skyBlue-300 dark:border-neutral-300">
                            {filterData?.length === 0 ? (
                              <>
                                <td colspan="6">
                                  <h4 className="text-center dark:text-white my-4 w-100 text-primary">
                                    No Found
                                  </h4>
                                </td>
                              </>
                            ) : (
                              <>
                                {filterData?.map((row) => {
                                  const statusLabel =
                                    tabs.find((tab) => tab.value === row.status)
                                      ?.label || "Unknown";
                                  return (
                                    <tr className=" border-[1px] border-skyBlue-300 dark:border-neutral-300  ">
                                      <td className="text-skyBlue-300 text-center border-[1px] border-skyBlue-300 dark:border-neutral-300 text-primary font-bold px-4">
                                        {row.event.name}
                                      </td>
                                      <td className="dark:text-skyBlue-300 border-[1px] border-skyBlue-300 dark:border-neutral-300 text-primary bold px-4">
                                        <strong>
                                          {row.order_id ? row.order_id : "-"}
                                        </strong>
                                      </td>

                                      <td className=" border-[1px] border-skyBlue-300 dark:text-white dark:border-neutral-300 px-4">
                                        {row.date_of_booking
                                          ? row.date_of_booking
                                          : "-"}
                                      </td>

                                      <td className="  border-[1px] border-skyBlue-300 dark:border-neutral-300 text-skyBlue-200 dark:text-neutral-300 pl-4">
                                        {row.seats_qty}
                                      </td>
                                      <td className="border-[1px] border-skyBlue-300 dark:border-neutral-300  font-semibold  px-4">
                                        <div
                                          className={`rounded-md p-[2px] text-center text-sm text-white ${
                                            statusLabel === "Pending"
                                              ? "bg-yellow-500"
                                              : "bg-green-600"
                                          }`}
                                        >
                                          {statusLabel}
                                        </div>
                                      </td>
                                      <td className=" border-[1px] border-skyBlue-300 dark:text-white dark:border-neutral-300 px-4">
                                        {row.grand_total}
                                        {"€"}
                                      </td>

                                      <td className=" border-[1px] border-skyBlue-300  dark:border-neutral-300 rounded-lg px-4">
                                        <div className="flex gap-1">
                                          <button
                                            className="text-white rounded-2xl dark:text-white p-1 dark:bg-skyBlue-300 my-2 bg-skyBlue-300 "
                                            onClick={() =>
                                              viewDetailsHandle(row.id)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                              />
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                              />
                                            </svg>
                                          </button>

                                          {/* {row.status == 1 && (
                                            <button
                                              onClick={toggleRating}
                                              className="text-white rounded-2xl dark:text-white  dark:bg-skyBlue-300    p-1 my-2 bg-skyBlue-300 "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6"
                                              >
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                                                />
                                              </svg>
                                            </button>
                                          )} */}
                                        </div>
                                        <RatingModal
                                          isOpen={isRatingModal}
                                          toggleRating={toggleRating}
                                          form={row}
                                        />
                                        <Modal
                                          isOpen={openModal}
                                          // onAfterOpen={afterOpenModal}
                                          style={customStyles}
                                          contentLabel="Example Modal"
                                        >
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyItems: "center",
                                                flexDirection: "column",
                                                marginTop: 10,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 24,
                                                  fontWeight: "bold",

                                                  textAlign: "center",
                                                }}
                                                className="text-skyBlue-300 dark:text-neutral-300"
                                              >
                                                {`Are you sure for delete?`}
                                              </span>

                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  marginTop: 40,
                                                }}
                                              >
                                                <div
                                                  onClick={() => {
                                                    handleCancelOrder();
                                                    setOpenModal(false);
                                                  }}
                                                  style={{
                                                    borderRadius: 100,

                                                    paddingRight: 30,
                                                    paddingLeft: 30,
                                                    paddingTop: 8,
                                                    paddingBottom: 8,
                                                    cursor: "pointer",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    width: 167,
                                                    height: 36,
                                                    margin: 5,
                                                  }}
                                                  className="bg-skyBlue-300 dark:bg-neutral-700 border-skyBlue-300 dark:border-neutral-200"
                                                >
                                                  <span
                                                    style={{
                                                      color: "#000",
                                                      fontSize: 18,
                                                      fontWeight: "500",
                                                    }}
                                                    className="text-skyBlue-300 dark:text-neutral-300"
                                                  >
                                                    Yes
                                                  </span>
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    setEditData("");
                                                    setOpenModal(false);
                                                  }}
                                                  style={{
                                                    background: "#fff",
                                                    borderRadius: 20,
                                                    paddingRight: 30,
                                                    paddingLeft: 30,
                                                    paddingTop: 8,
                                                    paddingBottom: 8,
                                                    borderWidth: 2,
                                                    btyle: "solid",
                                                    cursor: "pointer",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    width: 167,
                                                    height: 36,
                                                    margin: 5,
                                                  }}
                                                  className=" border-skyBlue-300  dark:border-neutral-700"
                                                >
                                                  <span
                                                    style={{
                                                      color: "",
                                                      fontSize: 18,
                                                      fontWeight: "900",
                                                    }}
                                                    className="text-skyBlue-300  dark:text-neutral-300"
                                                  >
                                                    No
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Modal>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    "No Record Found"
                  )}
                </>
              ) : (
                <>
                  {/* Order details Section */}
                  <div>
                    <div className="w-full flex items-center justify-between">
                      <div className="w-full flex justify-between">
                        <h1 className="text-3xl font-semibold dark:text-[#E5E7EB] text-[#111827]">
                          Booking Detail
                        </h1>
                        <button
                          onClick={goBackHandle}
                          class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10 bg-gray-900 text-gray-50 dark:bg-gray-50 dark:text-gray-900"
                        >
                          <FaArrowLeft className="w-4 h-4" />
                          {/* <span class="sr-only">Back</span> */}
                        </button>
                      </div>
                      {/* <ButtonPrimary
                        className="btn btn-primary py-1"
                        onClick={goBackHandle}>
                        Go Back
                      </ButtonPrimary>
                      <h2 className="text-3xl font-semibold">Order Detail</h2> */}
                    </div>

                    <article>
                      {orderDetails ? (
                        <div class="mx-auto max-w-5xl grid gap-4  py-4 lg:gap-8">
                          <div class="flex flex-col md:grid md:grid-cols-6 gap-3">
                            <div class="md:col-span-6">
                              <div
                                class="rounded-lg border bg-card  shadow-sm"
                                data-v0-t="card"
                              >
                                <div class="flex flex-col  space-y-1.5 p-6 bg-skyBlue-300 dark:bg-skyBlue-300">
                                  <h3 class="text-xl font-semibold whitespace-nowrap leading-none tracking-tight text-white dark:text-white">
                                    Event Details
                                  </h3>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-4 dark:bg-transparent bg-white p-6">
                                  <div className="col-md-1">
                                    <img
                                      className="w-36 rounded-lg"
                                      src={orderDetails?.event?.image_url}
                                    ></img>
                                  </div>
                                  <div class="col-md-1 col-span-3 gap-4">
                                    <div className=" my-2 flex items-center justify-start">
                                      <p className="text-md font-semibold dark:text-white text-[#111827] md:mr-4">
                                        Event Name:{" "}
                                      </p>
                                      <p className="dark:text-white">
                                        {orderDetails?.event?.name}
                                      </p>
                                    </div>

                                    <div className=" ">
                                      <div className="my-2 ">
                                        <div class=" dark:text-white font-semibold text-gray-900 ">
                                          Concert Date:
                                        </div>
                                        <div className="dark:text-white">
                                          {orderDetails?.date_of_booking}
                                        </div>
                                      </div>
                                      <div className>
                                        <div class="font-semibold text-gray-900 dark:text-white">
                                          Venue:
                                        </div>
                                        <div className="dark:text-white">
                                          {orderDetails?.event?.venue?.name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="md:col-span-4 lg:col-span-3 xl:col-span-4 flex flex-col gap-6">
                              <div
                                class="rounded-lg border bg-card text-card-foreground shadow-sm"
                                data-v0-t="card"
                              >
                                <div class="flex flex-col space-y-1.5 p-4 bg-skyBlue-300 dark:bg-skyBlue-300">
                                  <h3 class="text-xl font-semibold whitespace-nowrap leading-none tracking-tight text-white dark:text-white">
                                    Pick Up Information
                                  </h3>
                                </div>
                                <div class="p-4 dark:bg-white bg-white">
                                  <div class="grid gap-4">
                                    <div>
                                      <div class="font-semibold text-gray-900 dark:text-black">
                                        Pick Up Location:
                                      </div>
                                      <div>
                                        {orderDetails?.pickup?.pickup_address}
                                      </div>
                                    </div>
                                    <div>
                                      <div class="font-semibold text-gray-900 dark:text-black">
                                        Pick Up Time:
                                      </div>
                                      <div>
                                        {orderDetails?.pickup?.pickup_time}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="rounded-lg border bg-card text-card-foreground shadow-sm"
                                data-v0-t="card"
                              >
                                <div class="flex flex-col space-y-1.5 p-4 bg-skyBlue-300 dark:bg-skyBlue-300">
                                  <h3 class="text-xl font-semibold whitespace-nowrap leading-none tracking-tight text-white dark:text-white">
                                    Passengers
                                  </h3>
                                </div>
                                <div class="p-4 bg-white">
                                  <div class="relative w-full overflow-auto">
                                    <table class="w-full caption-bottom text-sm">
                                      <thead class="[&amp;_tr]:border-b">
                                        <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                          <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                                            Name
                                          </th>
                                          <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                                            Number
                                          </th>
                                          <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                                            Download Ticket
                                          </th>
                                        </tr>
                                      </thead>

                                      {orderAttendee ? (
                                        <>
                                          {orderAttendee.map((row, index) => {
                                            return (
                                              <>
                                                <tbody class="[&amp;_tr:last-child]:border-0">
                                                  <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                                    <td class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                                                      <div class="font-semibold">
                                                        {row.attendee_name}
                                                      </div>
                                                    </td>
                                                    <td class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                                                      {row.attendee_number}
                                                    </td>
                                                    {/* <td class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                                                      <ButtonPrimary
                                                        onClick={() =>
                                                          viewTicket(row)
                                                      
                                                        }
                                                        className="rounded-md"
                                                        variant="outline"
                                                      >
                                                        Download
                                                      </ButtonPrimary>
                                                    </td> */}
                                                    <td class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                                                      <a
                                                        href={`https://admin.eventbus.ie/download-ticket/${row.booking_id}/${row.id}/`}
                                                        className="rounded-md"
                                                        target="_blank"
                                                        variant="outline"
                                                      >
                                                        <ButtonPrimary>
                                                          Download
                                                        </ButtonPrimary>
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        " "
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="rounded-lg border bg-card text-card-foreground shadow-sm"
                                data-v0-t="card"
                              >
                                <div class="flex flex-col space-y-1.5 p-4 bg-skyBlue-300 dark:bg-skyBlue-300">
                                  <h3 class="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight text-white dark:text-white">
                                    Payment
                                  </h3>
                                </div>
                                <div class="p-4 bg-white grid gap-4">
                                  <div class="flex items-center">
                                    <div>Subtotal</div>
                                    <div class="ml-auto">
                                      {orderDetails?.total}€
                                    </div>
                                  </div>
                                  <div class="flex items-center">
                                    <div>Discount</div>
                                    <div class="ml-auto">
                                      {orderDetails?.discount}€
                                    </div>
                                  </div>

                                  <div
                                    data-orientation="horizontal"
                                    role="none"
                                    class="shrink-0 bg-gray-100 h-[1px] w-full"
                                  ></div>
                                  <div class="flex items-center font-medium">
                                    <div>Grand Total</div>
                                    <div class="ml-auto">
                                      {orderDetails?.grand_total} €
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="  p-2 flex items-center justify-start gap-2">
                                {/* <Link to="/events/all-events/6">
                                  <ButtonPrimary className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-9 rounded-md px-3 bg-skyBlue-300 text-white dark:bg-gray-50 dark:text-white">
                                    Order Again
                                  </ButtonPrimary>
                                </Link> */}
                                <Link
                                  to={orderDetails?.invoice_url}
                                  target="_blank"
                                  download
                                >
                                  <ButtonPrimary class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input h-9 rounded-md px-3 bg-skyBlue-300 text-white dark:bg-skyBlue-300 dark:text-white">
                                    Download invoice
                                  </ButtonPrimary>
                                </Link>
                              </div>
                            </div>

                            <div class="md:col-span-2 lg:col-span-3 xl:col-span-2 flex flex-col gap-2">
                              <div
                                class="rounded-lg bg-white border bg-card text-card-foreground shadow-sm"
                                data-v0-t="card"
                              >
                                <div class="flex flex-col space-y-1.5 p-4 bg-skyBlue-300 dark:bg-skyBlue-300">
                                  <h3 class="text-xl font-semibold whitespace-nowrap leading-none tracking-tight text-white dark:text-white">
                                    Customer Information
                                  </h3>
                                </div>
                                <div class="p-4 text-sm">
                                  <div class="grid gap-1">
                                    <div class="font-semibold capitalize text-gray-900 dark:text-black">
                                      <span className="pr-5">Name :</span>{" "}
                                      {orderDetails?.billing_fname}{" "}
                                      {orderDetails?.billing_lname}
                                    </div>

                                    <div>
                                      <span className="pr-5">Phone :</span>{" "}
                                      {orderDetails?.billing_phone}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}
                    </article>

                    {/* {orderDetails ? (
                      <>
                        <div className="bg-white p-3 my-4 rounded-xl">
                          <div className=" border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>Concert Date</strong>

                            <p>{orderDetails?.date_of_booking}</p>
                          </div>

                          <div className="my-2 border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>County you wish to travel</strong>

                            <p>{orderDetails?.pickup?.town}</p>
                          </div>

                          <div className="my-2 border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>Pick Up Points & Departure Times :</strong>
                            <p>
                              {orderDetails?.pickup?.pickup_address} At{" "}
                              {orderDetails?.pickup?.formatted_pickup_time}
                            </p>
                          </div>

                          <div className="my-2 border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>Payment Method</strong>
                            <p>{orderDetails?.payment_method}</p>
                          </div>

                          <div className="my-2 border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>Sub Total</strong>
                            <p>{orderDetails?.total}</p>
                          </div>

                          <div className="my-2 border-b border-dashed text-md text-[#111827] flex justify-between">
                            <strong>Grand Total </strong>
                            <p>{orderDetails?.grand_total}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Skeleton height={400} count={1} />
                    )} */}
                  </div>
                  {/* Tickets details Section */}

                  {/* <div className="w-full mx-auto bg-white rounded-lg shadow-md ">
                    <div className="py-2 px-4 bg-[#f9f9f9] rounded-md border-gray-300 border-r bordr-l border-t font-bold text-lg  ">
                      Passenger Details
                    </div>
                    <table className="px-4 w-full">
                      <thead>
                        <tr>
                          <th className="w-1/3">Name</th>
                          <th className="w-1/3">Phone Number</th>
                          <th className="w-1/3">Ticket</th>
                        </tr>
                      </thead>
                      {orderAttendee ? (
                        <tbody className="text-center">
                          {orderAttendee?.map((row, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="w-1/3">{row.attendee_name}</td>
                                  <td className="w-1/3">
                                    {" "}
                                    {row.attendee_number}
                                  </td>
                                  <td className="w-1/3">
                                    <ButtonPrimary
                                      onClick={() => viewTicket(row)}
                                      className="rounded-md"
                                      variant="outline"
                                    >
                                      Download
                                    </ButtonPrimary>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      ) : (
                        " "
                      )}
                    </table>
                  </div> */}

                  {/* <div className="bg-white rounded-md ticket-details-section">
                    <div className="px-4 bg-[#f9f9f9] rounded-md border-gray-300 border d-flex align-items-center justify-space-between">
                      <h2 className="text-xl py-2 text-[#111827] font-semibold">
                        Passenger Details
                      </h2>
                    </div>
                    {orderAttendee ? (
                      <>
                        <div className="bg-white p-2  table-responsive">
                          <table className="productDetailsTable w-full">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Ticket</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderAttendee?.map((row, index) => {
                                return (
                                  <tr key={index} className="p-1 text-center">
                                    <td>
                                      <>{row.attendee_name}</>
                                    </td>

                                    <td className="text-center">
                                     
                                       {row.attendee_number}
                                     
                                    </td>

                                    <td className="text-center">
                                      <ButtonPrimary
                                        className="btn rounded-md btn-primary py-1"
                                        onClick={() => viewTicket(row)}
                                      >
                                        View
                                      </ButtonPrimary>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <Skeleton height={400} count={1} />
                    )}
                  </div> */}

                  {/* {orderDetails ? (
                    <>
                      <div className="flex my-4 align-items-center justify-space-between">
                        <h2 className="text-xl  text-[#111827] font-semibold">
                          Event Details
                        </h2>
                      </div>

                      <div className="bg-white my-4 p-3 rounded-md">
                        <table className=" productDetailsTable w-full">
                          <thead className="">
                            <tr>
                              <th>Event name</th>
                              <th>Event Address</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <>{orderDetails?.event.name}</>
                              </td>

                              <td>{orderDetails?.event?.event_address}</td>

                              <td>
                                <>{orderDetails?.event?.date_concert}</>
                              </td>
                             
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex align-items-center justify-space-between">
                        <h2 className="text-xl my-4 text-[#111827] font-semibold">Bus Details</h2>
                      </div>

                      <div>
                        <table
                          className="productDetailsTable w-full"
                          
                        >
                          <thead className="position-sticky">
                            <tr>
                              <th>Bus name</th>
                              <th>Bus Type</th>
                          
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <>{orderDetails?.bus?.bus_title}</>
                              </td>
                              <td>
                                <table className="w-full">
                                  <tr>
                                    <td >
                                      {orderDetails?.bus?.bus_type}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                             
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton height={400} count={1} />
                    </>
                  )} */}

                  {/* <div className="billing-info">
                    <h4 className="text-primary text-xl font-semibold mb-2">
                      Billing Information
                    </h4>
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center">
                        <span className="font-semibold mr-2">Name:</span>
                        <span className=" capitalize">
                          {orderDetails?.billing_fname}{" "}
                          {orderDetails?.billing_lname}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-semibold mr-2">Phone:</span>
                        <span>{orderDetails?.billing_phone}</span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-semibold mr-2">Email:</span>
                        <span>{orderDetails?.billing_email}</span>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="flex justify-end flex-wrap gap-2 text-end my-4">
                    <Link to="/events/all-events/6">
                      <ButtonPrimary className="rounded-md  btn btn-primary py-1">
                        Order Again
                      </ButtonPrimary>
                    </Link>

                    <div>
                      <Link
                        to={orderDetails?.invoice_url}
                        target="_blank"
                        download
                      >
                        <ButtonPrimary className="rounded-md  btn btn-primary py-1">
                          Download Invoice
                        </ButtonPrimary>
                      </Link>
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
      {LoadingData && (
        <div className="bg-overlay">
          <InfinitySpin color="#00c9ff" />
        </div>
      )}
    </div>
  );
};

export default AccountSavelists;
