import React from "react";
import { Route, Routes } from "react-router-dom";
import AllRoutes from ".";
import axios from "axios";
import NotFoundPage from "../Pages/NotFound/NotFound";
const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_LIVE;
  return (
    <React.Fragment>
      <div>
        <Routes>
          {AllRoutes.map((item) => (
            <Route
              key={item.path}
              element={<item.element />}
              path={item.path}
              exact={true}
              render={({ location }) => {
                const query = new URLSearchParams(location.search);
                if (query.get("search")) {
                  return <NotFoundPage />;
                }
                return null;
              }}
            />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default App;
