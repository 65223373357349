import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../Utils/api";
import { InfinitySpin } from "react-loader-spinner";

const Event = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    callApi(`event-category`, {}, "get")
      .then((res) => {
        if (res.data.status === 200) {
          setData(res?.data?.result);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
      
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <section>
        <div className="flex justify-between flex-wrap w-full gap-6">
          {loading ? (
            <div className="w-full flex justify-center ">
              <InfinitySpin
                visible={true}
                width="200"
                color="#00c4ff"
                ariaLabel="infinity-spin-loading"
              />
            </div>
          ) : data?.length == 0 ? (
            <div className=" w-full flex justify-center">
              <h4 className="text-center text-4xl md:text-4xl  text-darkOrange-500 dark:text-neutral-300 font-semibold text-primary">
                No Events Available !
              </h4>
            </div>
          ) : (
            data?.map((item, index) => (
              <>
                <div
                  className="capitalize overflow-hidden bg-cover bg-center relative  width-30 rounded-md "
                 
                >
                  <Link
                    to={"/events" + "/" + item.category_slug + "/" + item.id}
                  >
                    <img
                      src={item.category_image}
                      alt="Column 1 Background"
                      className="transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-125 hover:bg-indigo-500 duration-300 w-full h-full hover:ease-out  transform "
                    />
                    <div className=" absolute inset-0 bg-black  bg-opacity-50 flex items-center justify-center "
                     style={{
                      pointerEvents:'none'
                    }}
                    >
                      <p className="text-white text-center text-[40px]">
                        {item.name}
                      </p>
                    </div>
                  </Link>
                </div>
              </>
            ))
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Event;
