import React, { useState, ChangeEvent } from "react";

interface SimpleOption {
  name: string;
  id: number;
}

interface DropdownProps {
  label: string;
  value: any;
  options: SimpleOption[] | null | undefined;
  name: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  time: string;
}

const Travel: React.FC<DropdownProps> = ({
  label,
  value,
  options,
  onChange,
  name,
}) => {

  return (
    <div className="">
      <label className="pb-1 block font-normal text-white dark:text-white text-sm  mb-1">
        {label}<span className="text-[#CB3C5F]"> *</span>
      </label>
      <select
        value={value.id}
        name={name}
        onChange={onChange}
        className=" w-full bg-transparent border text-[#b6b8c9] border-[#262625] rounded-md py-2 px-3 focus:outline-none text-sm ">
        <option value="">Select {name}</option>
        {(options || []).map((option: any) => (
          <option
            key={option.id}
            value={option.id}
            className="sm:w-full  md:w-1/2 lg:w-1/3 xl:w-1/4">
            {option.name}
           
          </option>
        ))}
      </select>
    </div>
  );
};

export default Travel;
