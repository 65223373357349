import React, { useState } from "react";
import { callApi } from "../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import "./StarRating.css";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";

const RatingModal = ({ isOpen, toggleRating, form }) => {
  // const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const handleRatingChange = (value) => {
    setRating(value);

  };


  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };



  const submitForm = (e) => {
    if(!rating){
      toast.error("Please Select Rating ")
    }else{
      toast.success("Thankyou For Feedback")
      toggleRating(false)
      setRating("")
    }
  };

  return (
    <div className={`${
      isOpen ? "block" : "hidden"
    } fixed inset-0 w-full h-full bg-[#fff3f380] bg-opacity-5 flex items-center justify-center z-50`}>
      <div className="flex justify-center items-center dark:bg-[#2B2B2B]  bg-white md:w-1/4 lg:w-1/4 border-2 rounded-3xl border-skyBlue-200 p-6">
        <div className="w-full">
          <h2 className="text-3xl text-center text-skyBlue-300 font-bold mb-4">Rating</h2>
          <div className="flex justify-center w-full mb-6">
            {[...Array(5)].map((_, index) => (
              <div className="flex" key={index}>
                {index < rating ? (
                  <svg
                    onClick={() => handleRatingChange(index - 0)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-8 h-8 flex text-skyBlue-300"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-8 h-8 cursor-pointer flex dark:text-white text-black"
                    onClick={() => handleRatingChange(index + 1)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                    />
                  </svg>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-wrap gap-2  w-full">
            <ButtonPrimary className=" md:mx-2 w-full bg-skyBlue-300 " onClick={submitForm}>
              Submit
            </ButtonPrimary>
            <button
              className="md:mx-2 rounded-full text-white font-semibold p-3 w-full  bg-red-600"
              onClick={toggleRating}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default RatingModal;



