import React, { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import eye icons
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Layout";
import { callApi } from "../../Utils/api";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import { CiCircleCheck } from "react-icons/ci";

const ResetPassword = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    password: "",
    key: location?.state?.key,
    type: location?.state?.data?.type,
    email: location?.state?.data?.email,
  });
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [show, setShow] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "confirm_password") {
      setPasswordMatch(value === formValues.password);
    } else if (name === "password") {
      setPasswordMatch(value === formValues.confirm_password);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { confirm_password, password } = formValues;
    if (confirm_password === password) {
      try {
        const response = await callApi(
          "user/app/change-password",
          formValues,
          "post"
        );
      
        if (response.data?.status === 200) {
       
          setFormValues("");
          setShow(true);
        } else if (response.data?.status === 500) {
          toast.error(response.data?.errors?.password[0]);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data?.status === 500) {
            toast.error("Internal server error. Please try again later.");
          } else if (error.response.data?.errors?.password) {
            toast.error(error.response.data.errors.password[0]);
          } else {
            toast.error("An error occurred while changing password");
          }
        } else {
          toast.error("An error occurred while changing password");
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please Fill Password");
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <section className="county-section ">
        <div className="row p-3 rounded-2xl  flex flex-wrap justify-center">
          <div className="w-full  max-w-xl sm:h-[50%] dark:bg-[#1c1c1c] bg-white aspect-w-1 aspect-h-1 rounded-2xl px-6 md:px-12 py-8">
            {show ? (
              <>
                <section className="flex justify-center items-center ">
                  <div className="w-full text-center">
                    <CiCircleCheck className="w-full text-4xl text-center text-green-400" />
                    <h1 className="my-5 text-xl font-semibold">
                      Password Changed Successfully
                    </h1>
                    <div>
                      <ButtonPrimary>
                        <Link to="/login" className="text-white">
                          Click Here To Login
                        </Link>
                      </ButtonPrimary>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                <h1 className="text-4xl  text-skyBlue-300 font-semibold leading-[1.15] mb-5 text-center">
                  Reset Password
                </h1>

                <div className="mb-4 ">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium dark:text-white text-gray-600"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formValues.password}
                      onChange={handleChange}
                      className={`mt-1 p-2 border-[2px] dark:text-white ${
                        formValues.password && !passwordMatch
                          ? "border-red-500 ring ring-red-300"
                          : formValues.password && passwordMatch
                          ? "border-green-500 ring ring-green-300"
                          : ""
                      } rounded-xl w-full focus:outline-none  bg-white pr-10`}
                      placeholder="New Password"
                    />
                    <div
                      className="absolute inset-y-2 right-0  pr-3 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </div>
                  </div>
                  <div>
                    {formValues.password && !passwordMatch && (
                      <span className="text-red-500 text-sm mb-2">
                        Please enter a new password
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-4 ">
                  <label
                    htmlFor="confirm_password"
                    className="block text-sm  dark:text-white font-medium text-gray-600"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="confirm_password"
                      name="confirm_password"
                      value={formValues.confirm_password}
                      onChange={handleChange}
                      className={`mt-1 p-2 border-[2px] dark:text-white ${
                        formValues.confirm_password && !passwordMatch
                          ? "border-red-500 ring ring-red-300"
                          : formValues.confirm_password && passwordMatch
                          ? "border-green-500 ring ring-green-300"
                          : ""
                      } rounded-xl w-full focus:outline-none  bg-white pr-10`}
                      placeholder="Confirm Password"
                    />
                    <div
                      className="absolute inset-y-2 right-0  pr-3 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </div>
                  </div>
                  {formValues.confirm_password && !passwordMatch && (
                    <span className="text-red-500 text-sm  mb-2">
                      Please confirm your password
                    </span>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  className={`button font-bold py-2 px-4 rounded-md hover:shadow-xl w-[100%] ${
                    loading
                      ? "bg-[#F5F5F5] text-black"
                      : "bg-skyBlue-300 hover:bg-skyBlue-300 text-white "
                  } flex items-center justify-center`}
                >
                  {loading ? (
                    <InfinitySpin color="#00c4ff" width="50"></InfinitySpin>
                  ) : (
                    "Submit"
                  )}
                </button>

                {/* <Link to="/sign-up">
              <div className="mt-2 text-center flex items-center justify-center cursor-pointer ">
                <span className=" dark:text-white hover:text-[#626c6c] text-sm">
                  Don't have an account?
                  <span className="underline hover:text-skyBlue-400">
                    {" "}
                    Sign up
                  </span>
                </span>
              </div>
            </Link> */}
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </section>
    </React.Fragment>
  );
};

export default Layout(ResetPassword);
