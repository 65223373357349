// LogoComponent.js
import React from "react";
import { Link } from "react-router-dom";
import { UseTheme } from "./MainToggle";
import Logo from "../../images/logo.png";
import DarkLogo from "../../images/DarkLogo.png";

const LogoComponent = ({ classname = ""}) => {
  const { themeMode , } = UseTheme();
  const currentLogo = themeMode === "light" ? Logo : DarkLogo;

  // const currentLogo= Logo
  return (
    <Link to="/">
      <img
        src={currentLogo}
        className={`${classname} max-w-36`}
        alt=""
      />
    </Link>
  );
};

export default LogoComponent;
