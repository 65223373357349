
import React, { useState } from "react";
import Select from "react-select";

const PickupDropdown = ({
  options,
  selectedValue,
  handlePickupChange
}) => {
  const [selectValue, setSelectvalue] = useState(null);
  const data = options.map((item) => ({
    pickup_id: `${item.id}`,
    pickup_name: `${item.pickup_address}`,
    town: `${item.town}`,
    landmark: `${item.landmark}`,
    time: item.formatted_pickup_time,
    price: `${parseFloat(item.price).toFixed(2)}`,
  }));

  const formatOptionLabel = ({ town, landmark, time, price }) => (
    <div className="grid grid-cols-2 py-2 place-content-between items-center justify-between">
    <div className="flex  items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        className="mr-2"
        fill="none"
      >
        <path
          d="M20.6201 8.45C19.5701 3.83 15.5401 1.75 12.0001 1.75C12.0001 1.75 12.0001 1.75 11.9901 1.75C8.4601 1.75 4.4201 3.82 3.3701 8.44C2.2001 13.6 5.3601 17.97 8.2201 20.72C9.2801 21.74 10.6401 22.25 12.0001 22.25C13.3601 22.25 14.7201 21.74 15.7701 20.72C18.6301 17.97 21.7901 13.61 20.6201 8.45ZM12.0001 13.46C10.2601 13.46 8.8501 12.05 8.8501 10.31C8.8501 8.57 10.2601 7.16 12.0001 7.16C13.7401 7.16 15.1501 8.57 15.1501 10.31C15.1501 12.05 13.7401 13.46 12.0001 13.46Z"
          fill="url(#paint0_linear_403_46)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_403_46"
            x1="13.4586"
            y1="10.3098"
            x2="20.1181"
            y2="4.5428"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00E4FF" />
            <stop offset="1" stop-color="#00E4FF" />
          </linearGradient>
        </defs>
      </svg>
      <span
      className="w-full text-ellipsis overflow-hidden"
      >
        {" "}

      {town}{" "}{landmark}
      </span>
    </div>
    <div className="flex justify-evenly gap-2">
      <p className="text-sm p-1 rounded-md bg-[#f5f5f5]  flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-3 h-3 mr-2 text-skyBlue-300"
        >
          <path
            fill-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
            clip-rule="evenodd"
          />
        </svg>
        <span> {time}</span>
      </p>
      <p className="text-sm p-1  rounded-md bg-[#f5f5f5] flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-3 h-3 mr-2 text-skyBlue-300"
        >
          <path
            fill-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>{"€"}{price}</span>
      </p>
    </div>
  </div>
  );

  const handleChange = (selectedOption) => {
    const selectedPickupId = selectedOption.pickup_id;
    setSelectvalue(selectedOption);
    handlePickupChange(selectedOption);
   
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      borderRadius: "8px",
      borderColor: "#00c4ff",
      boxShadow: "#00c4ff",
      "&:hover": {
        borderColor: "#00c4ff",
       
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ffff" : "#ffff",
      color: state.isSelected ? "#000" : "#000",
      borderColor: state.isSelected ? "#fff" : "#000",
      padding: "8px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#f3f4f6" : "#f3f4f6",
        color: state.isSelected ? "#000" : "#000",
        borderColor: state.isSelected ? "#fff" : "#00c4ff",
      },
    }),
  };

  return (
    <div className="mb-4 text-">
      <Select
        options={data}
        isSearchable={false}
        formatOptionLabel={formatOptionLabel}
        onChange={handleChange}
        value={selectValue}
        styles={customStyles}
      />
    </div>
  );
};


export default PickupDropdown;
