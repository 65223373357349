import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../Utils/api";
import { InfinitySpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Layout from "../../Layout/index";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import { MdOutlineCalendarMonth } from "react-icons/md";

const EventListCom = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const params = useParams();
  const location = useLocation();
  let str = params?.name;
  let newStr = str?.replace(/_/g, " ");

  
  useEffect(() => {
    getData();
  }, [location.pathname]);

  const getData = () => {
    setLoading(true);
    callApi(`event?category_id=${params?.id ? params?.id : 6}`, {}, "get")
      .then((res) => {
        if (res?.data?.status === 200) {
          setData(res?.data?.data);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
            setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <section className="my-8 md:my-4">
        <div className=" gap-6">
          {data?.length == 0 ? (
            <>
              {" "}
              <div className="flex flex-col items-center justify-center h-screen  gap-4 p-4 text-center">
                <div className="flex flex-col items-center gap-2">
                  <MdOutlineCalendarMonth className="h-16 w-16 text-3xl text-gray-500 dark:text-gray-400" />
                  <div className="text-xl dark:text-white text-[#111827] font-bold">
                    No events available
                  </div>
                  <p className="max-w-[600px] text-gray-500 dark:text-gray-400">
                    There are no events available in the selected category at
                    this time.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <Link
                    className="inline-block w-full md:w-auto"
                    to="/events/all-events/6"
                  >
                    <ButtonPrimary className="text-md rounded-md">
                      Back to All Events
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {data?.map((item, index) => (
                <>
                  <div className=" h-full  ">
                    <div className="bg-white rounded-2xl shadow-orange  dark:bg-[#2B2B2B]">
                      <Link
                        to={{
                          pathname: `/product-details/${item.product_slug}`,
                          state: item.id,
                        }}
                      >
                        <div className="overflow-hidden">
                          <div className="flex justify-center ">
                            <img
                              src={item?.image}
                              alt={item?.name}
                              className="rounded-t-2xl "
                            />
                          </div>
                          <div className="p-4 space-y-0">
                            <div className="space-y-0">
                              {/* <h1>ev</h1> */}
                              <div className="flex items-center space-x-2">
                                {/* {isAds && <Badge name="ADS" color="green" />} */}
                                <h2 className="text-lg">
                                  <span className="line-clamp-1 dark:text-[#D8DBDE] text-[#111827] bold text-base font-semibold">
                                    {item.name}
                                  </span>
                                </h2>
                              </div>
                            </div>
                            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                            <div className="flex items-center">
                              <span className="line-clamp-1  dark:text-[#c6d6f1] text-skyBlue-300 bold text-base font-semibold">
                                {item.price} €
                              </span>
                              <span className="line-clamp-1 dark:text-[#D8DBDE] text-[#111827] bold text-base font-normal">
                                /seat
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center  transform transition-all duration-500 hover:scale-110">

      
    </div> */}
                      </Link>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
        {loading && (
          <div className="bg-overlay">
            <InfinitySpin color="#00c9ff" />
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default Layout(EventListCom);
