import React, { useEffect, useState } from "react";
import BusMap from "../../images/bus-map.png";
import BookNow from "./Statistics";
import Event from "../Events/event";
import SwitchDarkMode from "../../Components/Switch/SwitchMode";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import LogoComponent from "../../Components/Switch/Logo";
import { InfinitySpin } from "react-loader-spinner";
import { ThemeProvider } from "../../Components/Switch/MainToggle";
const Home = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isLoad, setIsLoad] = useState(true);
  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };
  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 1000);
  }, []);

  const handleLogout = () => {
  
    localStorage.setItem("token", "");
    localStorage.setItem("isGuest", false);
    localStorage.setItem("user", "");
    window.location.reload();
  };

  return (
    <React.Fragment>
      <ThemeProvider>
        <div className="bg-white  dark:bg-black">
          <section className=" container relative md:pt-0 pt-6 md:pb-10  mx-auto  home-Section">
            <div className=" absolute  md:pr-16 right-6 top-3 md:right-5 gap-2  flex justify-end ">
              <div>
                {localStorage.getItem("token") ? (
                  <Link to="/Myaccount/dashboard">
                    <div
                      className={`
                      cursor-pointer hover:border border-[#f3f4f6]  w-8  h-8 md:w-12 md:h-12 flex items-center justify-center rounded-full dark:bg-[#0e1010]   hover:bg-white  bg-[#f3f4f6]
                       ${
                         hoveredLink === "Logout"
                           ? "text-[#00C4FF]"
                           : "text-[#00C4FF]"
                       } md:mr-5`}
                      onMouseEnter={() => handleMouseEnter("Logout")}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FaUser className="md:w-6 md:h-6 w-4 h-4 rounded-full text-[#374151] dark:text-[#374151]" />
                    </div>
                  </Link>
                ) : (
                  <div className=" w-full absolute right-10 md:right-32  flex justify-end ">
                    <Link to="/login">
                      <button
                        className={`bg-skyBlue-300 font-semibold w-28 text-white py-3 px-4 rounded-md      ${
                          hoveredLink === "Sign In"
                            ? "text-[#00C4FF]"
                            : "text-[#00C4FF]"
                        } me-3`}
                        onMouseEnter={() => handleMouseEnter("Sign In")}
                        onMouseLeave={handleMouseLeave}
                      >
                        Sign In
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              <SwitchDarkMode />
            </div>
            <div className=" px-8 md:px-20 ">
              <div className="grid md:grid-cols-12">
                <div className="md:absolute overflow-visible">
                  <div className=" md:pt-10 col-sm-3">
                    <LogoComponent classname="max-w-44 md:max-w-full"/>
                  </div>

                  <div className=" py-2 md:py-16 items-center   justify-center">
                    <h1 className="text-4xl md:text-5xl dark:text-white text-[#303030] font-medium leading-[1.15]">
                      Direct from all
                      <br />
                      <span className=" font-bold text-[#00C4FF]">
                        irish Cities
                      </span>
                    </h1>
                    <p className=" mt-3 md:mt-5 dark:text-white font-medium text-md md:text-xl">
                      Book Your Individual Bus Seats <br />
                      By Various Categories
                    </p>
                  </div>
                </div>

                <div className="col-start-3  hidden md:block pt-2 col-span-12">
                  <img className="overflow-hidden" src={BusMap}></img>
                </div>
              </div>
              <div className=" mt-6">
                <BookNow />
                <div className="mt-16">
                  <Event />
                </div>
              </div>
            </div>
          </section>
        </div>
        {isLoad && (
          <div className="bg-overlay">
            <InfinitySpin color="#00c9ff" />
          </div>
        )}
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Home;
