import React from "react";
import Header from "./Header/Header";

const Layout = (Component) => {
  return () => (
    <section className="dark:bg-black ">
        <Header />
      <div className="bg-[#F1F2F5] dark:bg-black">
        <div  className="container mx-auto  py-2 px-4 md:px-20">
          <Component />
        </div>
      </div>
    </section>
  );
};

export default Layout;










