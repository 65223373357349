import Home from "../Pages/Home/Home";
import ProductDetails from "../Pages/ProductDetail/ProductDetails";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import ForgotPassword from "../Pages/ForgotPassword";
import EventList from "../Pages/EventListCom/EventListCom";
import CheckOut from "../Pages/CheckOut/CheckOut";
import Myaccount from "../Pages/Myaccount/Myaccount";
import PayDone from "../Pages/PayDone/PayDone";
import OrderDetail from "../Pages/Myaccount/OrderDetail";
import ResetPassword from "../Pages/ForgotPassword/ResetPassword";

const AllRoutes = [
  { name: "Home", path: "/", element: Home },
  {
    name: "ProductDetailPage",
    path: "/product-details/:name",
    element: ProductDetails,
  },
  { name: "Login", path: "/login", element: Login },
  { name: "SignUp", path: "/sign-up", element: SignUp },
  { name: "EventList", path: "/events/:name/:id", element: EventList },
  { name: "ForgotPassword", path: "/forgot-password", element: ForgotPassword },
  { name: "checkout", path: "/checkout", element: CheckOut },
  { name: "Myaccount", path: "/Myaccount/:name", element: Myaccount },
  { name: "Paydone", path: "/Paydone", element: PayDone },
  { name: "OrderDetail", path: "/order-detail", element: OrderDetail },
  { name: "Reset Password", path: "/reset_password", element: ResetPassword },
];

export default AllRoutes;
