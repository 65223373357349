import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { callApi } from "../../Utils/api";
import { Close } from "@mui/icons-material";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import Logo from "../../images/logo.png";
// import SwitchDarkMode from "../../Components/Switch/SwitchMode";
import LogoComponent from "../../Components/Switch/Logo";

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
const navigate = useNavigate("")
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getData = () => {
    callApi(`event-category`, {}, "get")
      .then((res) => {
        if (res.data.status === 200) {
          setData(res?.data?.result);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
      
        setLoading(false);
      });
  };

  let getToken = localStorage.getItem("token");
  let getIsGuest = localStorage.getItem("isGuest");

  const LogoutUser = () => {
    localStorage.removeItem("token");
    navigate('/login')
  };

  const onClickClose = () => {};

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="block md:hidden">
        <FaBars onClick={handleToggle} className=" w-6 h-6 md:w-12 md:h-12  dark:text-[#667387]  dark:hover:text-skyBlue-300 text-[#111827] cursor-pointer" />
      </div>
      {isOpen && (
        <>
          <div className="fixed inset-0  z-50 bg-gray-800 bg-opacity-75  flex flex-col">
            <div className="w-4/5 dark:bg-black overflow-y-scroll bg-white h-screen  relative ">
              <div className="py-6 px-5 border-b border-skyBlue-300 ">
                <div className="flex justify-between items-center">
                  <LogoComponent/>
                  <Close
                    onClick={handleToggle}
                    className="w-7 h-7 rounded-full border border-skyBlue-300 text-skyBlue-300  hover:bg-skyBlue-200 hover:text-white cursor-pointer"
                  />
                </div>

                <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
                  <h4 className="text-2xl sm:text-2xl lg:text-2xl font-semibold mb-2 text-primary  text-skyBlue-500 dark:text-neutral-300">
                    Event & Concert Travel
                  </h4>
                  <span className=" text-skyBlue-500 dark:text-neutral-300">
                    Book your individual bus seats
                  </span>

                  <div className="flex justify-between items-center mt-4">
                   
                    <span className="block">
                      {/* <SwitchDarkMode /> */}
                    </span>
                  </div>

                 
                </div>
              </div>

              <div className="py-6 px-5  leading-6 text-skyBlue-300 border-b border-skyBlue-300  flex flex-col items-start justify-between gap-2 text-left">
                {data.map((item) => (
                  <div className="hover:bg-skyBlue-300 p-2 rounded-xl hover:text-white w-full">

                  <Link
                    key={item.id}
                    to={`/events/${item.category_slug}/${item.id}`}
                    onClick={handleToggle}
                  >
                    <span
                      key={item.id}
                      className={`cursor-pointer ${
                        item.id === data.id
                          ? "dark:text-skyBlue-300 text-skyBlue-300"
                          : ""
                      }  leading-2  dark:text-white  font-semibold capitalize text-lg   rounded-md dark:hover:text-skyBlue-400`}
                    >
                      {item.name}
                    </span>
                  </Link>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-between py-6 px-5 space-x-4">
          {getToken ? (
            <ButtonPrimary className="w-full" onClick={LogoutUser}>
              Logout
            </ButtonPrimary>
          ) : (
            <>
            <div className="flex justify-between gap-2 w-full ">
              <Link to="/login" className="w-full" rel="noopener noreferrer" >
                <ButtonPrimary className="w-full">Login</ButtonPrimary>
              </Link>
              <Link to="/sign-up" className="w-full" rel="noopener noreferrer" >
                <ButtonPrimary className="w-full">Signup</ButtonPrimary>
              </Link>
              </div>
            </>
          )}
        </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileNav;
