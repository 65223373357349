import React, { useState, useEffect, memo } from "react";
import { callApi } from "../../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import ButtonPrimary from "../../../Components/Buttons/ButtonPrimary";
import { InfinitySpin } from "react-loader-spinner";
// import "loaders.css/loaders.min.css";

const AccountSavelists = (props) => {
  const getUserData = props.userData;
  const [errorMessage, setErrorMessage] = useState("");
  const validate = new SimpleReactValidator({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
  });
  const [isLoad, setIsLoad] = useState(false);



  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    isEditMode: false,
  });

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  useEffect(() => {
    getUserInfo();
  }, [isUpdated]);

  const getUserInfo = () => {
    setIsLoad(true);
    if (getUserData?.id) {
      callApi(`user/${getUserData?.id}`, {}, "get")
        .then((response) => {
          const resData = response.data.data;
          setUserInfo(resData);
          setFormData({
            ...formData,
            firstname: resData?.firstname,
            lastname: resData?.lastname,
            email: resData?.email,
            // mobile: resData?.mobile,
          });
          setIsLoad(false);
        })
        .catch((err) => {
      
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneNumber = (e) => {
    let enteredPhoneNumber = e.target.value;
    enteredPhoneNumber = enteredPhoneNumber.replace(/\D/g, "");
    enteredPhoneNumber = enteredPhoneNumber.slice(0, 10);
    setFormData({
      ...formData,
      mobile: enteredPhoneNumber,
    });
    const isValid = /^[789]\d{9}$/.test(enteredPhoneNumber);
    setIsValidPhoneNumber(isValid);
  };

  const handleEditClick = () => {
    setFormData({
      ...formData,
      isEditMode: true,
    });
  };

  const handleCancelClick = () => {
    // Reset formData to original state
    setFormData({
      ...getUserData,
      isEditMode: false,
    });
    setErrorMessage(" ");
  };

  // update the value in local storage
  const storedData = localStorage.getItem("user", JSON.stringify);
  let userData = storedData ? JSON.parse(storedData) : {};
  userData.firstname = formData.firstname;
  userData.lastname = formData.lastname;
  // userData.mobile = formData.mobile;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData) {
      if (validate.allValid()) {
        callApi(`user/update/${getUserData?.id}`, formData, "post").then(
          (response) => {
            const resError = response?.response?.data?.errors;
            const resMsg = response?.data?.msg;
           
            if (response?.data?.status === 200) {
              toast.success("User Info Updated Successfully");
              setFormData({
                ...formData,
                isEditMode: false,
              });
              localStorage.setItem("user", JSON.stringify(userData));
              props.handleUpdateUser(formData);
              setErrorMessage(resError);
              setIsUpdated(false);
            }
          }
        );
      } else {
        validate.showMessages();
        setErrorMessage(validate.errorMessages);
      }
    } else {
    }
  };

  return (
    <React.Fragment>
      <div className="dark:border-neutral-700  dark:bg-neutral-800 orderdetails-sec">
        <div className="space-y-2 sm:space-y-8 ">
          <div className="flex-content-between">
            <h2 className="text-2xl dark:text-white text-[#111827]  font-semibold">
              User Info
            </h2>
          </div>
          <div className="container lg:mb-32 mt-5 pb-5">
            <div className="">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="w-full mx-auto space-y-6 ">
                <form className=" mt-5">
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <div className="">
                      <label className="text-sm  block text-[#111827] dark:text-white ">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        className={` mt-1 p-2 dark:text-white border dark:border-[#676764] dark:bg-transparent 
                        rounded-md w-full bg-white focus:outline-none focus:border-gray-300
                      ${
                        !formData.isEditMode
                          ? ""
                          : "border border-skyBlue-400 focus:ring-skyBlue-200 focus:ring-opacity-60"
                      }`}
                        value={formData.firstname}
                        onChange={handleInputChange}
                        disabled={!formData.isEditMode}
                      />
                      <div className=" ">
                        {errorMessage && (
                          <p className="text-red-500 text-sm">
                            {errorMessage?.firstname}
                          </p>
                        )}
                        {validate.message(
                          "firstname",
                          formData?.firstname,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="">
                      <label className="text-sm dark:text-white ">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        className={`
                      
                      mt-1 p-2 dark:text-white border bg-white dark:border-[#676764] dark:bg-transparent 
                       rounded-md w-full focus:outline-none focus:border-gray-300
                    
                      ${
                        !formData.isEditMode
                          ? ""
                          : "border border-skyBlue-400 focus:ring-skyBlue-200 focus:ring-opacity-60"
                      }`}
                        value={formData.lastname}
                        onChange={handleInputChange}
                        disabled={!formData.isEditMode}
                      />
                      <div className=" ">
                        {validate.message(
                          "lastname",
                          formData?.lastname,
                          "required"
                        )}
                        {errorMessage && (
                          <p className="text-red-500 md:p-1 text-sm">
                            {errorMessage?.lastname}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="">
                      <label className="text-sm block text-[#111827] dark:text-white ">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="example@example.com"
                        className={`mt-1 p-2 dark:text-white bg-white border dark:border-[#676764] dark:bg-transparent 
                        rounded-md w-full focus:outline-none focus:border-gray-300
                      ${
                        !formData.isEditMode
                          ? ""
                          : ""
                      }`}
                        value={
                          userInfo.email ? userInfo.email : formData?.email
                        }
                        onChange={handleInputChange}
                        disabled
                        readOnly={true}
                      />
                    </div>

                    {/* <div className="">
                    <label className="w-full  block text-neutral-800 dark:text-white ">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Phone Number"
                      className={`mt-1 bg-white text-md px-4 py-2 p-2 focus:outline-none focus:ring focus:border-skyBlue-300 rounded-md w-full text-skyBlue-300
                      ${
                        !formData.isEditMode
                          ? ""
                          : " border border-skyBlue-400 focus:ring-skyBlue-200 focus:ring-opacity-60"
                      }`}
                      value={formData.mobile}
                      onChange={handlePhoneNumber}
                      disabled={!formData.isEditMode}
                    />
                    <div className=" ">
                      {!isValidPhoneNumber && (
                        <p className="text-red-500 text-sm">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </div>
                  </div> */}
                  </div>

                  <div className="my-4">
                    {!formData.isEditMode ? (
                      <ButtonPrimary
                        className="w-full py-1 "
                        onClick={handleEditClick}
                      >
                        Request To Edit
                      </ButtonPrimary>
                    ) : (
                      <div className="flex">
                        <ButtonPrimary
                          className="w-full py-1 mr-1"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </ButtonPrimary>
                        <ButtonPrimary
                          type="submit"
                          className="ml-1 py-1 w-full "
                          onClick={handleSubmit}
                        >
                          Update
                        </ButtonPrimary>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {isLoad && (
          <div className="bg-overlay">
            <InfinitySpin color="#00c9ff" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AccountSavelists;
