import React, { useState } from "react";
import { callApi } from "../../../Utils/api";
import { useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";

const Dashboard = () => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails) {
      if (userDetails.id) {
        getData(userDetails.id);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [0.8]);

  const getData = async (id) => {
    setLoading(true);
    const res = await callApi(`get-booking/${id}`, {}, "get");
    if (res?.data) {
      setData(res?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <h2 className="text-2xl font-semibold dark:text-[#D8DBDE] text-black mb-5">
        Dashboard
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 rounded-lg overflow-hidden ">
        <div className="border-l-4 h-20 bg-skyBlue-500 bg-opacity-10  rounded-lg w-full flex justify-between  items-center border-green-500 py-2 px-4">
          <div>
            <h2 className="dark:text-[#D8DBDE] text-black  text-md m-0 font-bold opacity-50 ">
              Total Booked Seats
            </h2>
            <p className="text-green-500  font-extrabold text-2xl ">
              <>{data.length}</>
            </p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
        </div>
        <div className="border-l-4 h-20 bg-skyBlue-500 bg-opacity-10 rounded-lg w-full flex justify-between  items-center border-red-500 px-4 py-2">
          <div>
            <h2 className="text-md m-0 opacity-50 font-bold dark:text-[#D8DBDE]  text-black">
              Total Canceled Seats
            </h2>
            <p className="text-red-500 font-extrabold text-2xl ">0</p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
        </div>

        <div className="border-l-4 h-20 bg-skyBlue-500 bg-opacity-10 rounded-lg w-full flex justify-between  items-center border-yellow-500 py-2 px-4">
          <div>
            <h2 className="text-md m-0 font-bold opacity-50 dark:text-[#D8DBDE] text-black ">
              Total Pending Seats
            </h2>
            <p className="text-yellow-500 font-extrabold text-2xl ">0</p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
        </div>
        {loading && (
          <div className="bg-overlay">
            <InfinitySpin color="#00c9ff" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
