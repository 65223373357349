import React from "react";

const ButtonPrimary = ({ className = "", ...args }) => {
  return (
    <button
      className={` p-3 rounded-md  text-white hover:bg-[#148fb5] bg-gradient-to-r from-[#29bed5] to-[#00c4ff] border-skyBlue-800 ring-skyBlue-300 font-medium border ring-opacity-2 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
