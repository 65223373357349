import Axios from "axios";
const AccessToken = localStorage.getItem("token");

Axios.defaults.headers.common["Authorization"] = `Bearer ${AccessToken}`;

export const callApi = async (url, data = {}, method = "GET") => {
  const axiosParams = {
    url,
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  if (method === "GET") {
    axiosParams.params = data;
  } else {
    axiosParams.data = data;
  }
 
  try {
    const resp = await Axios(axiosParams);
    return resp;
  } catch (err) {
    return err;
  }
};
