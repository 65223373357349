import React, { useEffect, useState } from "react";

import TravelDropdown from "../../../Components/Dropdown/TravelDropdown.tsx";
import DateDropdown from "../../../Components/Dropdown/DateDropdown.tsx";
import CountyDropdown from "../../../Components/Dropdown/CountyDropdown.tsx";
import ButtonPrimary from "../../../Components/Buttons/ButtonPrimary.jsx";
import { callApi } from "../../../Utils/api.js";
import { useLocation, useNavigate } from "react-router-dom";
import Bus from "../../../images/image.png";
import BusMap from "../../../images/bus-map.png";
import { toast, ToastContainer } from "react-toastify";
import PickupDropdown from "../../../Components/Dropdown/Travel1.jsx";

const BookNow = () => {
  const [booking, setBooking] = useState({});
  const [Events, setEvents] = useState([]);
  const [AllCounties, setAllCounties] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  //
  const getEvents = () => {
    setLoading(true);
    callApi(`event?category_id=${6}`, {}, "get")
      .then((res) => {
        if (res?.data?.status === 200) {
          const data = res?.data?.data;
          
          setEvents(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
   
        setLoading(false);
      });
  };


  // const handleEventChange = (selectedOption) => {
  //   const {name , value } = selectedOption
  //   setSelectedEvent(value); 
  //   setBooking({ ...booking, [name]: value });
  //     getCounties(value);
  // };

  const handleEventChange = (e) => {
  
    const { name, value } = e.target;
    setSelectedEvent(value);
    setBooking({ ...booking, [name]: value });
    getCounties(value);
  };


  //County API
  const getCounties = (eventId) => {
    setLoading(true);
    callApi(`/counties?event_id=${eventId}`, {}, "get")
      .then((res) => {
        if (res?.data?.data) {
          setAllCounties(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      
      });
  };

  useEffect(() => {
    getEvents();
  }, [location.pathname]);

  const handleCountySelect = (e) => {
    const selectedCountyId = e.target.value;
    const selectedCountyObject = AllCounties.find(
      (county) => county.id === parseInt(selectedCountyId)
    );
    if (selectedCountyObject) {
      setBooking({ ...booking, selectedCounty: selectedCountyObject });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setSelectedDate(value);
    setBooking({ ...booking, [name]: value });
  };

  const handleSubmit = () => {
    if (selectedEvent && selectedDate && selectedCounty) {
      const selectedEventObject = Events.find(
        (eventName) => eventName.id === parseInt(selectedEvent)
      );
      const slug = selectedEventObject?.product_slug;
      toast.success("Event added");
      navigate(`/product-details/${slug}`, {
        state: { booking, slug },
      });
    } else if (!selectedEvent) {
      toast.error("Please Select Event");
    } else if (!selectedDate) {
      toast.error("Please Select Date");
    } else {
      toast.error("Please Select County");
    }
  };

  return (
    <React.Fragment>
      <section className=" rounded-xl border border-[#262625] bg-[#0D0D0D] container mx-auto w-full my-2 overflow-hidden">
        <div className=" relative ">
      
          <img
            src={BusMap}
            className="hidden  md:block absolute rounded-r-2xl z-0  -right-[16rem] object-cover h-full  w-1/2"
            alt=""
          />
         
          <div className="grid w-full grid-cols-1 p-5 relative z-10  place-items-center md:place-items-end  md:grid-cols-5">
            <div className="w-full md:mr-2 p-2">
              {/* <PickupDropdown
              label="Select Events"
              value={selectedEvent}
              name="event"
              options={Events}
              onChange={handleEventChange}
              /> */}

              {/* <PickupDropdown
                label="Pick up Points"
                name="pickup"
                className="mb-2 "
                options={data?.pickup_points}
                handlePickupChange={(e) => {
                  Object.keys(e).map((item) => {
                    handleChange(item, e[item], index);
                  });
                }}
              /> */}

              <TravelDropdown
                label="Select Events"
                value={selectedEvent}
                name="event"
                options={Events}
                onChange={handleEventChange}
              />
            </div>

            <div className="w-full md:mr-2 p-2">
              <DateDropdown
                label="Select Date"
                options={Events}
                value={selectedDate}
                name="date"
                eventId={selectedEvent}
                onChange={handleDateChange}
              />
            </div>

            <div className="w-full md:mr-2 p-2">
              <CountyDropdown
                label="Select County"
                value={selectedCounty}
                options={selectedDate ? AllCounties : []}
                name="county"
                onChange={(e) => {
                  setSelectedCounty(e.target.value);
                  handleCountySelect(e);
                }}
              />
            </div>
            <div className="md:col-start-4 md:col-span-1  w-full gap-2 md:mt-0 mt-5 mb-2">
              <ButtonPrimary
                onClick={handleSubmit}
                className=" w-full h-[39px] md:w-44 md:py-2 rounded-md"
              >
                Book Now
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};
export default BookNow;
