import React, { useState, ChangeEvent } from "react";

interface Option {
  id: number;
  date_concert: string;
}

interface DropdownProps {
  label: string;
  value: any;
  options: Option[];
  name: string;
  id: any;
  date: any;
  index: any;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  eventId: number;
}


const formatDate = (dateString: string) => {
  const options:any = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};
const DateComponent: React.FC<DropdownProps> = ({
  label,
  value,
  options,
  onChange,
  id,
  name,
  eventId, 
}) => {



const filteredOptions = options.filter((option: any) => option.id == eventId);
const dateArray = filteredOptions.flatMap((option: any) =>
  option.date_concert.split(", ").map((date: any, index: number) => ({
    id: date, 
    date: formatDate(date),
  }))
);

  return (
    <div className="">
      <label className="mb-1 pb-1 block w-full text-white dark:text-neutral-300 text-sm font-normal ">
        {label} <span className="text-[#CB3C5F]"> *</span>
      </label>
      <select
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        className=" w-full bg-transparent border text-[#b6b8c9] border-[#262625] rounded-md py-2 px-3 focus:outline-none text-sm "
      >
        <option value="">Select {name}</option>
        {dateArray.map((dateOption: any) => (
          <option key={dateOption.id} value={dateOption.id}>
            {dateOption.date}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateComponent;
